<template>
    <div :id="editorId" style="width: 100%; height: 100%;"></div>
</template>

<script>
import * as ace from 'ace-builds'
// language package, choose your own 
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-javascript';
// ui-theme package
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/ext-beautify';

export default {
  name: 'Editor',
  props: {
      editorId: String, 
      content: String,
      readonly: {
          Type: Boolean,
          default: false
      }
  },
  data() {
      return {
          editor: Object,
          editorBeautify: ace.require('ace/ext/beautify'),
          beforeContent: '',
          hasErrors: false
      }
  },
  watch: {
    content: async function (newContent) {
        if (this.beforeContent !== newContent) {
            if(newContent != undefined) {
                let temp = JSON.parse(newContent)
                this.editor.getSession().setValue(JSON.stringify(temp, null, 4) , 1)
            } else {
                this.editor.getSession().setValue('' , 1)
            }
        }
    },
    readonly: async function (value) {
        this.editor.setReadOnly(value)
    }
  },
  mounted() {
    this.editor = ace.edit(this.editorId, {
      maxLines: 15,
      minLines: 15,
      mode: "ace/mode/json",
      bug: 1
    });
    if(this.content != undefined && this.content !== "")
        this.editor.getSession().setValue(JSON.stringify(JSON.parse(this.content), null, 4), 1)
    else
        this.editor.getSession().setValue('', 1)
    this.editor.setTheme("ace/theme/github");
    this.editor.getSession().setMode("ace/mode/json");
    this.editor.getSession().setUseWrapMode(true);
    //this.editorBeautify.beautify(this.editor.getSession())
    this.editor.setReadOnly(this.readonly);
    this.editor.on('change', () => {
        this.beforeContent = this.editor.getValue()
        this.$emit('change-content', this.editor.getValue())
    })
    this.editor.getSession().on('changeAnnotation', () => {
        this.hasErrors = this.getErrors()
        this.$emit('content-valid', !this.hasErrors)
    })    
  },
  methods: {
      getErrors() {
        var annotations = this.editor.getSession().getAnnotations();
        var errs = 0
        annotations.forEach(function (anno, index) {
            console.log(JSON.stringify(anno))
            if(anno.type === 'error')
                errs++
        });
        return errs>0
      }
  }
}
</script>