<template>
  <div>
    <div v-if="status === 'ready'">
      <CCard>
        <CCardHeader>
          <strong>{{ isCCoEBaseline() ? roleShortName : nameExt }}</strong
          ><small> form </small>
        </CCardHeader>
        <CCardBody>
          <CAlert show color="info" v-if="isCCoEBaseline() && !readOnly"
            >This is a Permission Set managed by OneCloud team. You cannot
            change anything !!
          </CAlert>
          <CAlert show color="info" v-if="readOnly"
            >You cannot modify this Permission Set !</CAlert
          >
          <CInput
            label="Name"
            placeholder="Enter permission set name (maximum 19 chars)"
            :prepend="isCCoEBaseline() ? '' : accountId"
            v-model="roleShortName"
            :disabled="!creationMode || readOnly"
            :is-valid="nameValidator"
          />
          <CInput
            label="Descritpion"
            placeholder="Enter description"
            v-model="roleDescription"
            :disabled="isCCoEBaseline()"
          />
          <CInput
            v-c-tooltip="{
              content: 'In seconds, must be < 43200',
              placement: 'left',
            }"
            label="Session duration"
            placeholder="Enter the session duration"
            v-model="sessionDuration"
            :is-valid="sessionValidator"
            :disabled="readOnly || isCCoEBaseline()"
          />
          <label>AWS Managed Policies</label>
          <AwsManagedPoliciesComponent
            @add="addPolicy($event)"
            v-if="!isCCoEBaseline() && !readOnly"
          />
          <hr class="d-sm-down-none" />
          <CDataTable
            :hover="true"
            :striped="true"
            :border="false"
            :small="true"
            :fixed="true"
            :items="awsManagedPolicies"
            :fields="fields"
            :items-per-page="10"
            :dark="false"
            :header="false"
            pagination
            clickableRows
            @row-clicked="managedPoliciesSelect"
          >
            <template #action="{ item }">
              <td class="py-2">
                <CButton
                  color="danger"
                  square
                  size="sm"
                  @click="removePolicy(item)"
                  v-if="!isCCoEBaseline() && !readOnly"
                  >Remove</CButton
                >
              </td>
            </template>
          </CDataTable>
          <hr class="d-sm-down-none" />
          <label
            v-c-tooltip="{
              content: 'Edit your policy here in JSON format',
              placement: 'right',
            }"
            >{{ policyInline }}</label
          >
          <Editor
            editorId="jsonedit"
            :content="inlinePolicy"
            @change-content="updateInlinePolicy"
            @content-valid="policyValidator"
            :readonly="isCCoEBaseline() || readOnly"
          />
        </CCardBody>
      </CCard>
    </div>
    <div v-else-if="status === 'loading'" align="center">
      <img src="/img/tenor.gif" width="50" />
    </div>
    <div v-else align="center">
      <CButton color="info" square size="sm" @click="loadPermissionSet"
        >Click to reload Permission Set</CButton
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AwsManagedPolicies from "./mockAWSManagedPolicies";
import Editor from "../../components/Editor.vue";
import AwsManagedPoliciesComponent from "./TableAWSManagedPolicies";

async function fetchPermissionSet(accountId, accessToken, name) {
  //return mockPermissionSet;
  try {
    const config = {
      headers: {
        authorizationToken: "Bearer " + accessToken
      }
    };
    const URL =
      process.env.VUE_APP_BACKEND_BASEURL +
      "/accounts/" +
      accountId +
      "/roles/" +
      name;
    const response = await axios.get(URL, config);

    // 2XX status code only in this block
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      // status code that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // no response
      console.log(error.request);
    } else {
      // other
      console.log("Error", error.message);
    }
    throw new Error("There was an error while fetching the permission set");
  }
}

async function fetchAwsManagedPolicies(accessToken) {
  return AwsManagedPolicies;
}

async function createPermissionSet(
  accountId,
  permissionSet,
  update,
  accessToken
) {
  console.log("createPermissionSet", JSON.stringify(permissionSet));
  try {
    const config = {
      headers: {
        authorizationToken: "Bearer " + accessToken,
        "Content-Type": "application/json"
      }
    };
    let URL =
      process.env.VUE_APP_BACKEND_BASEURL + "/accounts/" + accountId + "/roles";
    let response;
    if (update) {
      URL += "/" + accountId + "." + permissionSet.RoleName;
      delete permissionSet.RoleName;
      response = await axios.put(URL, permissionSet, config);
    } else {
      response = await axios.post(URL, permissionSet, config);
    }

    // 2XX status code only in this block
    console.log(response.data);
    return true;
  } catch (error) {
    if (error.response) {
      // status code that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // no response
      console.log(error.request);
    } else {
      // other
      console.log("Error", error.message);
    }
    throw new Error("There was an error while processing your demand");
  }
}

export default {
  name: "FormPermissionSet",
  components: { Editor, AwsManagedPoliciesComponent },
  props: {
    name: {
      type: String,
      default: "new"
    },
    caption: {
      type: String,
      default: "Permission Set"
    },
    creationMode: {
      type: Boolean,
      default: true
    },
    accountId: String,
    item: {
      type: Object,
      default: undefined
    },
    bus: {
      // an event bus
      type: Object,
      default: undefined
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    policyInline: function() {
      if (this.inlinePolicy != undefined) {
        let cleanText = this.inlinePolicy.replace(/\s/g, "");
        return "Policy inline: " + cleanText.length + "/10240 chars (max)";
      }
      return "Policy inline ";
    },
    nameExt: function() {
      return (
        this.caption + " (" + this.accountId + "." + this.roleShortName + ") "
      );
    },
    shortName: function() {
      var re = /[0-9]+\.(.+)/i;
      var found = this.item.RoleName.match(re);
      return found[1];
    },
    description: function() {
      return this.item.RoleDescription;
    }
  },
  watch: {
    item: async function(newItem) {
      console.log("new item:" + newItem);
      this.resetForm();
      if (newItem != undefined) {
        this.loadPermissionSet();
      } else {
        this.creationMode = true;
        // this.loading = false
        this.status = "ready";
      }
    }
  },
  data() {
    return {
      loading: false,
      awsManagedPolicyList: undefined,
      selectedAwsManagedPolicy: undefined,
      awsManagedPolicies: [],
      roleShortName: this.creationMode ? undefined : this.shortName,
      roleDescription: this.creationMode ? undefined : this.description,
      sessionDuration: "3600",
      inlinePolicy: undefined,
      fields: ["label", "action"],
      status: "ready",
      validation: {
        name: true,
        sessionDuration: true,
        inlinePolicy: true
      }
    };
  },
  async created() {
    var accessToken = await this.$auth.getAccessToken();
    this.awsManagedPolicyList = await fetchAwsManagedPolicies(accessToken);
    if (this.bus != undefined) {
      // register some events
      this.bus.$on("cancel", this.cancel);
      this.bus.$on("submit", this.submit);
    }
  },
  methods: {
    resetForm() {
      this.selectedAwsManagedPolicy = undefined;
      this.awsManagedPolicies = [];
      this.roleShortName = undefined;
      this.roleDescription = undefined;
      this.sessionDuration = "3600";
      this.inlinePolicy = undefined;
    },
    async loadPermissionSet() {
      this.creationMode = false;
      // this.loading = true
      this.status = "loading";
      this.roleShortName = this.creationMode ? undefined : this.shortName;
      this.roleDescription = this.creationMode ? undefined : this.description;
      var accessToken = await this.$auth.getAccessToken();
      try {
        var ps = await fetchPermissionSet(
          this.accountId,
          accessToken,
          this.accountId + "." + this.roleShortName
        );
        // fetch data success !
        for (const [key, policy] of Object.entries(ps.SsoRole.RolePolicies)) {
          if (policy.Type === "AWS_MANAGED") {
            this.awsManagedPolicies.push({
              value: policy.Arn,
              label: policy.Name
            });
          } else {
            this.inlinePolicy = policy.PolicyDocument;
          }
        }
        if (ps.SsoRole.hasOwnProperty("RoleSessionDuration"))
          this.sessionDuration = ps.SsoRole.RoleSessionDuration;

        // this.loading = false
        this.status = "ready";
      } catch (err) {
        // TODO
        this.$store.commit("showErrorModal", ["Error", err.message]);
        // this.loading = true
        this.status = "error";
      }
    },
    addPolicy(event) {
      // find item based on name
      if (this.awsManagedPolicies.length >= 10) {
        return;
      }
      var policy = this.awsManagedPolicyList.find(x => x.name === event.name);

      // check if already added
      var addedPolicy = this.awsManagedPolicies.find(
        x => x.value === policy.arn
      );

      if (addedPolicy == undefined) {
        this.awsManagedPolicies.push({ value: policy.arn, label: policy.name });
      }
    },
    removePolicy(event) {
      if (event.value != undefined) {
        // find item in list
        var idx = this.awsManagedPolicies.findIndex(
          x => x.value === event.value
        );
        if (idx >= 0) {
          this.awsManagedPolicies.splice(idx, 1);
        }
      }
    },
    managedPoliciesSelect(event) {
      console.log(event);
      this.selectedAwsManagedPolicy = event.label;
    },
    updateInlinePolicy(content) {
      this.inlinePolicy = content;
    },
    cancel(event) {
      // just return without doing anything
    },
    async submit(event) {
      if (this.status === "ready") {
        this.$store.commit("set", ["busymodalShow", true]);
        if (!this.isCCoEBaseline()) {
          try {
            let target = this.buildPayload();
            var accessToken = await this.$auth.getAccessToken();
            console.log("submit" + JSON.stringify(target));
            let res = await createPermissionSet(
              this.accountId,
              target,
              !this.creationMode,
              accessToken
            );
            if (this.creationMode) this.$emit("permissionset-created", target);
          } catch (err) {
            this.$store.commit("showErrorModal", ["Error", err.message]);
          } finally {
            this.$store.commit("set", ["busymodalShow", false]);
          }
        } else {
          this.$store.commit("set", ["busymodalShow", false]);
        }
      }
      // else cannot submit while loading
    },
    sessionValidator(val) {
      this.validation.sessionDuration = val >= 0 && val <= 43200;
      this.isFormValid();
      return this.validation.sessionDuration;
    },
    nameValidator(val) {
      if (val == undefined) this.validation.name = false;
      else if (val.length > 19) this.validation.name = false;
      else {
        const regex = /^([A-Z]|[a-z]|[0-9])+$/g;
        const found = val.match(regex);
        this.validation.name = !(found == null);
      }
      this.isFormValid();
      return this.validation.name;
    },
    policyValidator(val) {
      if (this.inlinePolicy != undefined) {
        let cleanText = this.inlinePolicy.replace(/\s/g, "");
        this.validation.inlinePolicy = val && cleanText.length < 10241;
      } else {
        this.validation.inlinePolicy = val;
      }
      this.isFormValid();
    },
    isFormValid() {
      var valid =
        this.validation.name &&
        this.validation.inlinePolicy &&
        this.validation.sessionDuration;

      this.$emit("form-valid", valid);
      return valid;
    },
    isCCoEBaseline() {
      // return this.$store.state.isCCoEBaseline(this.roleShortName);
      return this.$isCCoEBaseline(this.roleShortName);
    },
    buildPayload() {
      let permissionSetData = {
        RoleName: this.roleShortName,
        RoleDescription: this.roleDescription,
        RoleSessionDuration: this.sessionDuration + "",
        AwsManagedPolicyArns: [],
        CustomerInlinePolicy: null
      };

      if (this.awsManagedPolicies != undefined) {
        permissionSetData.AwsManagedPolicyArns = this.awsManagedPolicies.map(
          item => {
            return item.value;
          }
        );
      }

      if (this.inlinePolicy != undefined) {
        console.log(this.inlinePolicy);
        permissionSetData.CustomerInlinePolicy = this.inlinePolicy;
      } else {
        delete permissionSetData.CustomerInlinePolicy;
      }

      if (this.isCCoEBaseline()) {
        // remove from payload
        delete permissionSetData.AwsManagedPolicyArns;
        delete permissionSetData.CustomerInlinePolicy;
      }

      return permissionSetData;
    }
  }
};
</script>
