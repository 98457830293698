const awsManagedPolicies = [
  {
    arn: "arn:aws:iam::aws:policy/AWSDirectConnectReadOnlyAccess",
    description: "Provides read only access to AWS Direct Connect via the AWS Management Console.",
    name: "AWSDirectConnectReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonGlacierReadOnlyAccess",
    description: "Provides read only access to Amazon Glacier via the AWS Management Console.",
    name: "AmazonGlacierReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMarketplaceFullAccess",
    description:
      "Provides the ability to subscribe and unsubscribe to AWS Marketplace software, allows users to manage Marketplace software instances from the Marketplace 'Your Software' page, and provides administrative access to EC2.",
    name: "AWSMarketplaceFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSSSODirectoryAdministrator",
    description: "Administrator access for SSO Directory",
    name: "AWSSSODirectoryAdministrator",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoT1ClickReadOnlyAccess",
    description: "Provides read only access to AWS IoT 1-Click.",
    name: "AWSIoT1ClickReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AutoScalingConsoleReadOnlyAccess",
    description: "Provides read-only access to Auto Scaling via the AWS Management Console.",
    name: "AutoScalingConsoleReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSHealthFullAccess",
    description: "Allows full access to the AWS Health Apis and Notifications and the Personal Health Dashboard",
    name: "AWSHealthFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AlexaForBusinessGatewayExecution",
    description: "Provide gateway execution access to AlexaForBusiness services",
    name: "AlexaForBusinessGatewayExecution",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElasticTranscoder_ReadOnlyAccess",
    description: "Grants users read-only access to Elastic Transcoder and list access to related services.",
    name: "AmazonElasticTranscoder_ReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRDSFullAccess",
    description: "Provides full access to Amazon RDS via the AWS Management Console.",
    name: "AmazonRDSFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/job-function/SupportUser",
    description:
      "This policy grants permissions to troubleshoot and resolve issues in an AWS account. This policy also enables the user to contact AWS support to create and manage cases.",
    name: "SupportUser",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEC2FullAccess",
    description: "Provides full access to Amazon EC2 via the AWS Management Console.",
    name: "AmazonEC2FullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/SecretsManagerReadWrite",
    description:
      "Provides read/write access to AWS Secrets Manager via the AWS Management Console. Note: this exludes IAM actions, so combine with IAMFullAccess if rotation configuration is required.",
    name: "SecretsManagerReadWrite",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonDocDBReadOnlyAccess",
    description:
      "Provides read-only access to Amazon DocumentDB with MongoDB compatibility. Note that this policy also grants access to Amazon RDS and Amazon Neptune resources.",
    name: "AmazonDocDBReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMQApiFullAccess",
    description: "Provides full access to AmazonMQ via our API/SDK.",
    name: "AmazonMQApiFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElementalMediaStoreReadOnly",
    description: "Provides read-only permissions for MediaStore APIs",
    name: "AWSElementalMediaStoreReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCertificateManagerReadOnly",
    description: "Provides read only access to AWS Certificate Manager (ACM).",
    name: "AWSCertificateManagerReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCloudMapRegisterInstanceAccess",
    description: "Provides registrant level access to AWS Cloud Map actions.",
    name: "AWSCloudMapRegisterInstanceAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMarketplaceImageBuildFullAccess",
    description:
      "Provides full access to AWS Marketplace Private Image Build Feature. In addition to create private images, it also provides permissions to add tags to images, launch and terminate ec2 instances.",
    name: "AWSMarketplaceImageBuildFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodeCommitPowerUser",
    description: "Provides full access to AWS CodeCommit repositories, but does not allow repository deletion.",
    name: "AWSCodeCommitPowerUser",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodeCommitFullAccess",
    description: "Provides full access to AWS CodeCommit via the AWS Management Console.",
    name: "AWSCodeCommitFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/IAMSelfManageServiceSpecificCredentials",
    description: "Allows an IAM user to manage their own Service Specific Credentials.",
    name: "IAMSelfManageServiceSpecificCredentials",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCloud9EnvironmentMember",
    description: "Provides the ability to be invited into AWS Cloud9 shared development environments.",
    name: "AWSCloud9EnvironmentMember",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSQSFullAccess",
    description: "Provides full access to Amazon SQS via the AWS Management Console.",
    name: "AmazonSQSFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AlexaForBusinessReadOnlyAccess",
    description: "Provide read only access to AlexaForBusiness services",
    name: "AlexaForBusinessReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/IAMFullAccess",
    description: "Provides full access to IAM via the AWS Management Console.",
    name: "IAMFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonInspectorFullAccess",
    description: "Provides full access to Amazon Inspector.",
    name: "AmazonInspectorFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElastiCacheFullAccess",
    description: "Provides full access to Amazon ElastiCache via the AWS Management Console.",
    name: "AmazonElastiCacheFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSAgentlessDiscoveryService",
    description: "Provides access for the Discovery Agentless Connector to register with AWS Application Discovery Service.",
    name: "AWSAgentlessDiscoveryService",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSXrayWriteOnlyAccess",
    description: "AWS X-Ray write only managed policy",
    name: "AWSXrayWriteOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSPriceListServiceFullAccess",
    description: "Provides full access to AWS Price List Service.",
    name: "AWSPriceListServiceFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AutoScalingReadOnlyAccess",
    description: "Provides read-only access to Auto Scaling. ",
    name: "AutoScalingReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonForecastFullAccess",
    description: "Gives access to all actions for Amazon Forecast",
    name: "AmazonForecastFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonWorkLinkReadOnly",
    description: "Grants read only access to Amazon WorkLink resources ",
    name: "AmazonWorkLinkReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/TranslateFullAccess",
    description: "Provides full access to Amazon Translate.",
    name: "TranslateFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AutoScalingFullAccess",
    description: "Provides full access to Auto Scaling.",
    name: "AutoScalingFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSFMMemberReadOnlyAccess",
    description: "Provides read only access to AWS WAF actions for AWS Firewall Manager member accounts",
    name: "AWSFMMemberReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEKSClusterPolicy",
    description:
      "This policy provides Kubernetes the permissions it requires to manage resources on your behalf. Kubernetes requires Ec2:CreateTags permissions to place identifying information on EC2 resources including but not limited to Instances, Security Groups, and Elastic Network Interfaces. ",
    name: "AmazonEKSClusterPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEKSWorkerNodePolicy",
    description: "This policy allows Amazon EKS worker nodes to connect to Amazon EKS Clusters.",
    name: "AmazonEKSWorkerNodePolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMobileHub_ReadOnly",
    description:
      "This policy may be attached to any User, Role, or Group, in order to grant users permission to list and view projects in AWS Mobile Hub. This also includes permissions to generate and download sample mobile app source code for each Mobile Hub project. It does not allow the user to modify any configuration for any Mobile Hub project.",
    name: "AWSMobileHub_ReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElasticTranscoder_FullAccess",
    description:
      "Grants users full access to Elastic Transcoder and the access to associated services that is required for full Elastic Transcoder functionality.",
    name: "AmazonElasticTranscoder_FullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonCloudDirectoryReadOnlyAccess",
    description: "Provides read only access to Amazon Cloud Directory Service. ",
    name: "AmazonCloudDirectoryReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudWatchAgentAdminPolicy",
    description: "Full permissions required to use AmazonCloudWatchAgent.",
    name: "CloudWatchAgentAdminPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSOpsWorksCMInstanceProfileRole",
    description: "Provides S3 access for instances launched by OpsWorks CM.",
    name: "AWSOpsWorksCMInstanceProfileRole",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodePipelineApproverAccess",
    description: "Provides access to view and approve manual changes for all pipelines",
    name: "AWSCodePipelineApproverAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSApplicationDiscoveryAgentAccess",
    description: "Provides access for the Discovery Agent to register with AWS Application Discovery Service.",
    name: "AWSApplicationDiscoveryAgentAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/job-function/ViewOnlyAccess",
    description: "This policy grants permissions to view resources and basic metadata across all AWS services. ",
    name: "ViewOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ElasticLoadBalancingFullAccess",
    description:
      "Provides full access to Amazon ElasticLoadBalancing, and limited access to other services necessary to provide ElasticLoadBalancing features.",
    name: "ElasticLoadBalancingFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRoute53DomainsReadOnlyAccess",
    description: "Provides access to Route53 Domains list and actions.",
    name: "AmazonRoute53DomainsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSSMAutomationApproverAccess",
    description: "Provides access to view automation executions and send approval decisions to automation waiting for approval",
    name: "AmazonSSMAutomationApproverAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSSecurityHubReadOnlyAccess",
    description: "Provides read only access to AWS Security Hub resources",
    name: "AWSSecurityHubReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEC2ContainerRegistryFullAccess",
    description: "Provides administrative access to Amazon ECR resources",
    name: "AmazonEC2ContainerRegistryFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonFSxFullAccess",
    description: "Provides full access to Amazon FSx and access to related AWS services.",
    name: "AmazonFSxFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/SimpleWorkflowFullAccess",
    description: "Provides full access to the Simple Workflow configuration service.",
    name: "SimpleWorkflowFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonS3FullAccess",
    description: "Provides full access to all buckets via the AWS Management Console.",
    name: "AmazonS3FullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSStorageGatewayReadOnlyAccess",
    description: "Provides access to AWS Storage Gateway via the AWS Management Console.",
    name: "AWSStorageGatewayReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/job-function/Billing",
    description:
      "Grants permissions for billing and cost management. This includes viewing account usage and viewing and modifying budgets and payment methods.",
    name: "Billing",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEC2ContainerRegistryReadOnly",
    description: "Provides read-only access to Amazon EC2 Container Registry repositories.",
    name: "AmazonEC2ContainerRegistryReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/job-function/DatabaseAdministrator",
    description: "Grants full access permissions to AWS services and actions required to set up and configure AWS database services.",
    name: "DatabaseAdministrator",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRedshiftReadOnlyAccess",
    description: "Provides read only access to Amazon Redshift via the AWS Management Console.",
    name: "AmazonRedshiftReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEC2ReadOnlyAccess",
    description: "Provides read only access to Amazon EC2 via the AWS Management Console.",
    name: "AmazonEC2ReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudWatchAgentServerPolicy",
    description: "Permissions required to use AmazonCloudWatchAgent on servers",
    name: "CloudWatchAgentServerPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSXrayReadOnlyAccess",
    description: "AWS X-Ray read only managed policy",
    name: "AWSXrayReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/WellArchitectedConsoleFullAccess",
    description: "Provides full access to AWS Well-Architected Tool via the AWS Management Console",
    name: "WellArchitectedConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElasticMapReduceReadOnlyAccess",
    description: "Provides read only access to Amazon Elastic MapReduce via the AWS Management Console.",
    name: "AmazonElasticMapReduceReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDirectoryServiceReadOnlyAccess",
    description: "Provides read only access to AWS Directory Service.",
    name: "AWSDirectoryServiceReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSSSOMasterAccountAdministrator",
    description: "Provides access within AWS SSO to manage AWS Organizations master and member accounts and cloud application",
    name: "AWSSSOMasterAccountAdministrator",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonVPCReadOnlyAccess",
    description: "Provides read only access to Amazon VPC via the AWS Management Console.",
    name: "AmazonVPCReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodeDeployRoleForECS",
    description:
      "Provides CodeDeploy service wide access to perform an ECS blue/green deployment on your behalf. Grants full access to support services, such as  full access to read all S3 objects, invoke all Lambda functions, publish to all SNS topics within the account and update all ECS services.",
    name: "AWSCodeDeployRoleForECS",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudWatchEventsReadOnlyAccess",
    description: "Provides read only access to Amazon CloudWatch Events.",
    name: "CloudWatchEventsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonAPIGatewayInvokeFullAccess",
    description: "Provides full access to invoke APIs in Amazon API Gateway.",
    name: "AmazonAPIGatewayInvokeFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonKinesisAnalyticsReadOnly",
    description: "Provides read-only access to Amazon Kinesis Analytics via the AWS Management Console.",
    name: "AmazonKinesisAnalyticsReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMobileAnalyticsFullAccess",
    description: "Provides full access to all application resources.",
    name: "AmazonMobileAnalyticsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMobileHub_FullAccess",
    description:
      "This policy may be attached to any User, Role, or Group, in order to grant users permission to create, delete, and modify projects (and their associated AWS resources) in AWS Mobile Hub. This also includes permissions to generate and download sample mobile app source code for each Mobile Hub project.",
    name: "AWSMobileHub_FullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudWatchFullAccess",
    description: "Provides full access to CloudWatch.",
    name: "CloudWatchFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMQApiReadOnlyAccess",
    description: "Provides read only access to AmazonMQ via our API/SDK.",
    name: "AmazonMQApiReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDeepLensLambdaFunctionAccessPolicy",
    description: "This policy specifies permissions required by DeepLens Administrative lambda functions that run on a DeepLens device",
    name: "AWSDeepLensLambdaFunctionAccessPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonGuardDutyFullAccess",
    description: "Provides full access to use Amazon GuardDuty.",
    name: "AmazonGuardDutyFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodePipelineReadOnlyAccess",
    description: "Provides read only access to AWS CodePipeline via the AWS Management Console.",
    name: "AWSCodePipelineReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ReadOnlyAccess",
    description: "Provides read-only access to AWS services and resources.",
    name: "ReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSAppSyncInvokeFullAccess",
    description: "Provides full invoking access to the AppSync service - both through the console and independently",
    name: "AWSAppSyncInvokeFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMachineLearningBatchPredictionsAccess",
    description: "Grants users permission to request Amazon Machine Learning batch predictions.",
    name: "AmazonMachineLearningBatchPredictionsAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTSiteWiseFullAccess",
    description: "Provides full access to IoT SiteWise.",
    name: "AWSIoTSiteWiseFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AlexaForBusinessFullAccess",
    description: "Grants full access to AlexaForBusiness resources and access to related AWS Services",
    name: "AlexaForBusinessFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRekognitionReadOnlyAccess",
    description: "Access to all Read rekognition APIs",
    name: "AmazonRekognitionReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodeDeployReadOnlyAccess",
    description: "Provides read only access to CodeDeploy resources.",
    name: "AWSCodeDeployReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudSearchFullAccess",
    description: "Provides full access to the Amazon CloudSearch configuration service.",
    name: "CloudSearchFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCloudHSMFullAccess",
    description: "Provides full access to all CloudHSM resources.",
    name: "AWSCloudHSMFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodeBuildDeveloperAccess",
    description:
      "Provides access to AWS CodeBuild via the AWS Management Console, but does not allow CodeBuild project administration. Also attach AmazonS3ReadOnlyAccess to provide access to download build artifacts.",
    name: "AWSCodeBuildDeveloperAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDataPipeline_PowerUser",
    description:
      "Provides full access to Data Pipeline, list access for S3, DynamoDB, Redshift, RDS, SNS, and IAM roles, and passRole access for default Roles.",
    name: "AWSDataPipeline_PowerUser",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonTranscribeFullAccess",
    description: "Provides full access to Amazon Transcribe operations",
    name: "AmazonTranscribeFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDirectoryServiceFullAccess",
    description: "Provides full access to AWS Directory Service.",
    name: "AWSDirectoryServiceFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonDynamoDBFullAccess",
    description: "Provides full access to Amazon DynamoDB via the AWS Management Console.",
    name: "AmazonDynamoDBFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSESReadOnlyAccess",
    description: "Provides read only access to Amazon SES via the AWS Management Console.",
    name: "AmazonSESReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRedshiftQueryEditor",
    description: "Provides full access to the Amazon Redshift Query Editor and to saved queries via the AWS Management Console.",
    name: "AmazonRedshiftQueryEditor",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSWAFReadOnlyAccess",
    description: "Provides read only access to AWS WAF actions.",
    name: "AWSWAFReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMechanicalTurkReadOnly",
    description: "Provides access to read only APIs in Amazon Mechanical Turk.",
    name: "AmazonMechanicalTurkReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonKinesisReadOnlyAccess",
    description: "Provides read only access to all streams via the AWS Management Console.",
    name: "AmazonKinesisReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSXRayDaemonWriteAccess",
    description:
      "Allow the AWS X-Ray Daemon to relay raw trace segments data to the service's API and retrieve sampling data (rules, targets, etc.) to be used by the X-Ray SDK.",
    name: "AWSXRayDaemonWriteAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCloudMapReadOnlyAccess",
    description: "Provides read-only access to all AWS Cloud Map actions.",
    name: "AWSCloudMapReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodeDeployFullAccess",
    description: "Provides full access to CodeDeploy resources.",
    name: "AWSCodeDeployFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSRoboMakerServiceRolePolicy",
    description: "RoboMaker service policy",
    name: "AWSRoboMakerServiceRolePolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudWatchActionsEC2Access",
    description:
      "Provides read-only access to CloudWatch alarms and metrics as well as EC2 metadata. Provides access to Stop, Terminate and Reboot EC2 instances.",
    name: "CloudWatchActionsEC2Access",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRoute53DomainsFullAccess",
    description:
      "Provides full access to all Route53 Domains actions and Create Hosted Zone to allow Hosted Zone creation as part of domain registrations.",
    name: "AmazonRoute53DomainsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElastiCacheReadOnlyAccess",
    description: "Provides read only access to Amazon ElastiCache via the AWS Management Console.",
    name: "AmazonElastiCacheReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonAthenaFullAccess",
    description:
      "Provide full access to Amazon Athena and scoped access to the dependencies needed to enable querying, writing results, and data management.",
    name: "AmazonAthenaFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElasticFileSystemReadOnlyAccess",
    description: "Provides read only access to Amazon EFS via the AWS Management Console.",
    name: "AmazonElasticFileSystemReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCloudMapDiscoverInstanceAccess",
    description: "Provides access to AWS Cloud Map discovery API.",
    name: "AWSCloudMapDiscoverInstanceAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudFrontFullAccess",
    description:
      "Provides full access to the CloudFront console plus the ability to list Amazon S3 buckets via the AWS Management Console.",
    name: "CloudFrontFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCloud9Administrator",
    description: "Provides administrator access to AWS Cloud9.",
    name: "AWSCloud9Administrator",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonTextractFullAccess",
    description: "Access to all Amazon Textract APIs",
    name: "AmazonTextractFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonDocDBFullAccess",
    description:
      "Provides full access to Amazon DocumentDB with MongoDB compatibility. Note this policy also grants full access to publish on all SNS topics within the account and full access to Amazon RDS and Amazon Neptune.",
    name: "AmazonDocDBFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMobileAnalyticsNon-financialReportAccess",
    description: "Provides read only access to non financial reports for all application resources.",
    name: "AmazonMobileAnalyticsNon-financialReportAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonCognitoDeveloperAuthenticatedIdentities",
    description: "Provides access to Amazon Cognito APIs to support developer authenticated identities from your authentication backend.",
    name: "AmazonCognitoDeveloperAuthenticatedIdentities",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSSSOMemberAccountAdministrator",
    description: "Provides access within AWS SSO to manage AWS Organizations member accounts and cloud application",
    name: "AWSSSOMemberAccountAdministrator",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCertificateManagerPrivateCAFullAccess",
    description: "Provides full access to AWS Certificate Manager Private Certificate Authority",
    name: "AWSCertificateManagerPrivateCAFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRedshiftFullAccess",
    description: "Provides full access to Amazon Redshift via the AWS Management Console.",
    name: "AmazonRedshiftFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonZocaloReadOnlyAccess",
    description: "Provides read only access to Amazon Zocalo",
    name: "AmazonZocaloReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCloudHSMReadOnlyAccess",
    description: "Provides read only access to all CloudHSM resources.",
    name: "AWSCloudHSMReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ComprehendFullAccess",
    description: "Provides full access to Amazon Comprehend.",
    name: "ComprehendFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonFSxConsoleFullAccess",
    description: "Provides full access to Amazon FSx and access to related AWS services via the AWS Management Console.",
    name: "AmazonFSxConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/job-function/SystemAdministrator",
    description: "Grants full access permissions necessary for resources required for application and development operations.",
    name: "SystemAdministrator",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRoute53ReadOnlyAccess",
    description: "Provides read only access to all Amazon Route 53 via the AWS Management Console.",
    name: "AmazonRoute53ReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSAppSyncSchemaAuthor",
    description: "Provides access to create, update, and query the schema.",
    name: "AWSAppSyncSchemaAuthor",
  },
  {
    arn: "arn:aws:iam::aws:policy/AlexaForBusinessDeviceSetup",
    description: "Provide device setup access to AlexaForBusiness services",
    name: "AlexaForBusinessDeviceSetup",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElasticBeanstalkWebTier",
    description: "Provide the instances in your web server environment access to upload log files to Amazon S3. ",
    name: "AWSElasticBeanstalkWebTier",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSQSReadOnlyAccess",
    description: "Provides read only access to Amazon SQS via the AWS Management Console.",
    name: "AmazonSQSReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonChimeFullAccess",
    description: "Provides full access to Amazon Chime Admin Console via the AWS Management Console.",
    name: "AmazonChimeFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDeepRacerRoboMakerAccessPolicy",
    description: "Allows RoboMaker to create required resources and call AWS services on your behalf.",
    name: "AWSDeepRacerRoboMakerAccessPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/WellArchitectedConsoleReadOnlyAccess",
    description: "Provides read-only access to AWS Well-Architected Tool via the AWS Management Console",
    name: "WellArchitectedConsoleReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonKinesisFullAccess",
    description: "Provides full access to all streams via the AWS Management Console.",
    name: "AmazonKinesisFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonGuardDutyReadOnlyAccess",
    description: "Provides read only access to Amazon GuardDuty resources",
    name: "AmazonGuardDutyReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonConnectReadOnlyAccess",
    description: "Grants permission to view the Amazon Connect instances in your AWS account.",
    name: "AmazonConnectReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMachineLearningReadOnlyAccess",
    description: "Provides read only access to Amazon Machine Learning resources.",
    name: "AmazonMachineLearningReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRekognitionFullAccess",
    description: "Access to all Amazon Rekognition APIs",
    name: "AmazonRekognitionFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMachineLearningFullAccess",
    description: "Provides full access to Amazon Machine Learning resources.",
    name: "AmazonMachineLearningFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AdministratorAccess",
    description: "Provides full access to AWS services and resources.",
    name: "AdministratorAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMachineLearningRealTimePredictionOnlyAccess",
    description: "Grants users permission to request Amazon Machine Learning real-time predictions.",
    name: "AmazonMachineLearningRealTimePredictionOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSConfigUserAccess",
    description:
      "Provides access to use AWS Config, including searching by tags on resources, and reading all tags. This does not provide permission to configure AWS Config, which requires administrative privileges.",
    name: "AWSConfigUserAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTConfigAccess",
    description: "This policy gives full access to the AWS IoT configuration actions",
    name: "AWSIoTConfigAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/SecurityAudit",
    description:
      "The security audit template grants access to read security configuration metadata. It is useful for software that audits the configuration of an AWS account.",
    name: "SecurityAudit",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDiscoveryContinuousExportFirehosePolicy",
    description: "Provides write access to AWS resources required for AWS Discovery Continuous Export",
    name: "AWSDiscoveryContinuousExportFirehosePolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElementalMediaConvertFullAccess",
    description: "Provides full access to AWS Elemental MediaConvert via the AWS Management Console and SDK.",
    name: "AWSElementalMediaConvertFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSRoboMakerReadOnlyAccess",
    description: "Provides read only access to AWS RoboMaker via the AWS Management Console and SDK",
    name: "AWSRoboMakerReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSResourceGroupsReadOnlyAccess",
    description: "This is the read only policy for AWS Resource Groups",
    name: "AWSResourceGroupsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodeStarFullAccess",
    description: "Provides full access to AWS CodeStar via the AWS Management Console.",
    name: "AWSCodeStarFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDataPipeline_FullAccess",
    description:
      "Provides full access to Data Pipeline, list access for S3, DynamoDB, Redshift, RDS, SNS, and IAM roles, and passRole access for default Roles.",
    name: "AWSDataPipeline_FullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/NeptuneFullAccess",
    description:
      "Provides full access to Amazon Neptune. Note this policy also grants full access to publish on all SNS topics within the account and full access to Amazon RDS. For more information, see https://aws.amazon.com/neptune/faqs/.",
    name: "NeptuneFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSSMManagedInstanceCore",
    description: "The policy for Amazon EC2 Role to enable AWS Systems Manager service core functionality.",
    name: "AmazonSSMManagedInstanceCore",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonDynamoDBReadOnlyAccess",
    description: "Provides read only access to Amazon DynamoDB via the AWS Management Console.",
    name: "AmazonDynamoDBReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AutoScalingConsoleFullAccess",
    description: "Provides full access to Auto Scaling via the AWS Management Console.",
    name: "AutoScalingConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElementalMediaPackageFullAccess",
    description: "Provides full access to AWS Elemental MediaPackage resources",
    name: "AWSElementalMediaPackageFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonKinesisVideoStreamsFullAccess",
    description: "Provides full access to Amazon Kinesis Video Streams via the AWS Management Console.",
    name: "AmazonKinesisVideoStreamsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSNSReadOnlyAccess",
    description: "Provides read only access to Amazon SNS via the AWS Management Console.",
    name: "AmazonSNSReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElasticMapReduceFullAccess",
    description:
      "This policy is on a deprecation path. See documentation for guidance: https://docs.aws.amazon.com/emr/latest/ManagementGuide/emr-managed-iam-policies.html. Provides full access to Amazon Elastic MapReduce and underlying services that it requires such as EC2 and S3",
    name: "AmazonElasticMapReduceFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCloudMapFullAccess",
    description: "Provides full access to all AWS Cloud Map actions.",
    name: "AWSCloudMapFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonS3ReadOnlyAccess",
    description: "Provides read only access to all buckets via the AWS Management Console.",
    name: "AmazonS3ReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonWorkSpacesAdmin",
    description: "Provides access to Amazon WorkSpaces administrative actions via AWS SDK and CLI.",
    name: "AmazonWorkSpacesAdmin",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSESFullAccess",
    description: "Provides full access to Amazon SES via the AWS Management Console.",
    name: "AmazonSESFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudWatchLogsReadOnlyAccess",
    description: "Provides read only access to CloudWatch Logs",
    name: "CloudWatchLogsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonKinesisFirehoseReadOnlyAccess",
    description: "Provides read only access to all Amazon Kinesis Firehose Delivery Streams.",
    name: "AmazonKinesisFirehoseReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/GlobalAcceleratorFullAccess",
    description: "Allow GlobalAccelerator Users full Access to all APIs",
    name: "GlobalAcceleratorFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonDynamoDBFullAccesswithDataPipeline",
    description:
      "This policy is on a deprecation path. See documentation for guidance: https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/DynamoDBPipeline.html. Provides full access to Amazon DynamoDB including Export/Import using AWS Data Pipeline via the AWS Management Console.",
    name: "AmazonDynamoDBFullAccesswithDataPipeline",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTAnalyticsReadOnlyAccess",
    description: "Provides read only access to IoT Analytics.",
    name: "AWSIoTAnalyticsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudWatchLogsFullAccess",
    description: "Provides full access to CloudWatch Logs",
    name: "CloudWatchLogsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSSecurityHubFullAccess",
    description: "Provides full access to use AWS Security Hub.",
    name: "AWSSecurityHubFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElementalMediaPackageReadOnly",
    description: "Provides read only access to AWS Elemental MediaPackage resources",
    name: "AWSElementalMediaPackageReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElasticBeanstalkMulticontainerDocker",
    description:
      "Provide the instances in your multicontainer Docker environment access to use the Amazon EC2 Container Service to manage container deployment tasks. ",
    name: "AWSElasticBeanstalkMulticontainerDocker",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMigrationHubFullAccess",
    description: "Managed policy to provide the customer access to the Migration Hub Service",
    name: "AWSMigrationHubFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonFSxReadOnlyAccess",
    description: "Provides read only access to Amazon FSx.",
    name: "AmazonFSxReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/IAMUserChangePassword",
    description: "Provides the ability for an IAM user to change their own password.",
    name: "IAMUserChangePassword",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonAPIGatewayAdministrator",
    description: "Provides full access to create/edit/delete APIs in Amazon API Gateway via the AWS Management Console.",
    name: "AmazonAPIGatewayAdministrator",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonVPCCrossAccountNetworkInterfaceOperations",
    description: "Provides access to create network interfaces and attach them to cross-account resources",
    name: "AmazonVPCCrossAccountNetworkInterfaceOperations",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonPollyReadOnlyAccess",
    description: "Grants read-only access to Amazon Polly resources.",
    name: "AmazonPollyReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRDSDataFullAccess",
    description:
      "Allows full access to use the RDS data APIs, secret store APIs for RDS database credentials, and DB console query management APIs to execute SQL statements on Aurora Serverless clusters in the AWS account.",
    name: "AmazonRDSDataFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMobileAnalyticsWriteOnlyAccess",
    description: "Provides write only access to put event data for all application resources. (Recommended for SDK integration)",
    name: "AmazonMobileAnalyticsWriteOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/job-function/DataScientist",
    description: "Grants permissions to AWS data analytics services.",
    name: "DataScientist",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMarketplaceMeteringFullAccess",
    description: "Provides full access to AWS Marketplace Metering.",
    name: "AWSMarketplaceMeteringFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/WorkLinkServiceRolePolicy",
    description: "Enables access to AWS Services and Resources used or managed by Amazon WorkLink",
    name: "WorkLinkServiceRolePolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSPrivateMarketplaceAdminFullAccess",
    description: "Provides full access to all administrative actions for an AWS Private Marketplace.",
    name: "AWSPrivateMarketplaceAdminFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSConnector",
    description:
      "Enables broad read/write access to ALL EC2 objects, read/write access to S3 buckets starting with 'import-to-ec2-', and the ability to list all S3 buckets, for the AWS Connector to import VMs on your behalf.",
    name: "AWSConnector",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodeDeployRoleForECSLimited",
    description: " Provides CodeDeploy service limited access to perform an ECS blue/green deployment on your behalf. ",
    name: "AWSCodeDeployRoleForECSLimited",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElasticTranscoder_JobsSubmitter",
    description:
      "Grants users permission to change presets, submit jobs, and view Elastic Transcoder settings. This policy also grants some read-only access to some other services required to use the Elastic Transcode console, including S3, IAM, and SNS.",
    name: "AmazonElasticTranscoder_JobsSubmitter",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTAnalyticsFullAccess",
    description: "Provides full access to IoT Analytics.",
    name: "AWSIoTAnalyticsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSBatchFullAccess",
    description: "Provides full access for AWS Batch resources.",
    name: "AWSBatchFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSSMDirectoryServiceAccess",
    description: "This policy allows SSM Agent to access Directory Service on behalf of the customer for domain-join the managed instance.",
    name: "AmazonSSMDirectoryServiceAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonECS_FullAccess",
    description:
      "Provides administrative access to Amazon ECS resources and enables ECS features through access to other AWS service resources, including VPCs, Auto Scaling groups, and CloudFormation stacks.",
    name: "AmazonECS_FullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSFMAdminReadOnlyAccess",
    description: "Read only access for AWS FM Administrator that allows monitoring AWS FM operations",
    name: "AWSFMAdminReadOnlyAccess",
  },
  { arn: "arn:aws:iam::aws:policy/AmazonSSMFullAccess", description: "Provides full access to Amazon SSM.", name: "AmazonSSMFullAccess" },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodeCommitReadOnly",
    description: "Provides read only access to AWS CodeCommit via the AWS Management Console.",
    name: "AWSCodeCommitReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonFreeRTOSFullAccess",
    description: "Full Access Policy for Amazon FreeRTOS",
    name: "AmazonFreeRTOSFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonCognitoReadOnly",
    description: "Provides read only access to Amazon Cognito resources.",
    name: "AmazonCognitoReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSApplicationDiscoveryServiceFullAccess",
    description: "Provides full access to view and tag Configuration Items maintained by the AWS Application Discovery Service ",
    name: "AWSApplicationDiscoveryServiceFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRoute53AutoNamingReadOnlyAccess",
    description: "Provides read-only access to all Route 53 Auto Naming actions.",
    name: "AmazonRoute53AutoNamingReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSSSOReadOnly",
    description: "Provides read only access to AWS SSO configurations.",
    name: "AWSSSOReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonVPCFullAccess",
    description: "Provides full access to Amazon VPC via the AWS Management Console.",
    name: "AmazonVPCFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCertificateManagerPrivateCAUser",
    description: "Provides certificate user access to AWS Certificate Manager Private Certificate Authority",
    name: "AWSCertificateManagerPrivateCAUser",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSAppSyncAdministrator",
    description: "Provides administrative access to the AppSync service, though not enough to access via the console.",
    name: "AWSAppSyncAdministrator",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRoute53AutoNamingFullAccess",
    description: "Provides full access to all Route 53 Auto Naming actions.",
    name: "AmazonRoute53AutoNamingFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSImportExportFullAccess",
    description: "Provides read and write access to the jobs created under the AWS account.",
    name: "AWSImportExportFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMechanicalTurkFullAccess",
    description: "Provides full access to all APIs in Amazon Mechanical Turk.",
    name: "AmazonMechanicalTurkFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEC2ContainerRegistryPowerUser",
    description:
      "Provides full access to Amazon EC2 Container Registry repositories, but does not allow repository deletion or policy changes.",
    name: "AmazonEC2ContainerRegistryPowerUser",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSSSODirectoryReadOnly",
    description: "ReadOnly access for SSO Directory",
    name: "AWSSSODirectoryReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMachineLearningCreateOnlyAccess",
    description: "Provides create access for non-prediction Amazon Machine Learning resources.",
    name: "AmazonMachineLearningCreateOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonKinesisVideoStreamsReadOnlyAccess",
    description: "Provides read only access to AWS Kinesis Video Streams via the AWS Management Console.",
    name: "AmazonKinesisVideoStreamsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCloudTrailReadOnlyAccess",
    description: "Provides read only access to AWS CloudTrail.",
    name: "AWSCloudTrailReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSLambdaExecute",
    description: "Provides Put, Get access to S3 and full access to CloudWatch Logs.",
    name: "AWSLambdaExecute",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSGlueConsoleSageMakerNotebookFullAccess",
    description: "Provides full access to AWS Glue via the AWS Management Console and access to sagemaker notebook instances.",
    name: "AWSGlueConsoleSageMakerNotebookFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMSKFullAccess",
    description: "Provide full access to Amazon MSK and other required permissions for its dependencies.",
    name: "AmazonMSKFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEKSServicePolicy",
    description:
      "This policy allows Amazon Elastic Container Service for Kubernetes to create and manage the necessary resources to operate EKS Clusters.",
    name: "AmazonEKSServicePolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMQReadOnlyAccess",
    description: "Provides read only access to AmazonMQ via the AWS Management Console.",
    name: "AmazonMQReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodePipelineCustomActionAccess",
    description:
      "Provides access for custom actions to poll for jobs details (including temporary credentials) and report status updates to AWS CodePipeline.",
    name: "AWSCodePipelineCustomActionAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSOpsWorksInstanceRegistration",
    description: "Provides access for an Amazon EC2 instance to register with an AWS OpsWorks stack.",
    name: "AWSOpsWorksInstanceRegistration",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonCloudDirectoryFullAccess",
    description: "Provides full access to Amazon Cloud Directory Service. ",
    name: "AmazonCloudDirectoryFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSStorageGatewayFullAccess",
    description: "Provides full access to AWS Storage Gateway via the AWS Management Console.",
    name: "AWSStorageGatewayFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTEventsFullAccess",
    description: "Provides full access to IoT Events. ",
    name: "AWSIoTEventsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonLexReadOnly",
    description: "Provides read-only access to Amazon Lex.",
    name: "AmazonLexReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonChimeUserManagement",
    description: "Provides user management access to Amazon Chime Admin Console via the AWS Management Console.",
    name: "AmazonChimeUserManagement",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMSKReadOnlyAccess",
    description: "Provide readonly access to Amazon MSK",
    name: "AmazonMSKReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDataSyncFullAccess",
    description: "Provides full access to AWS DataSync and minimal access to its dependencies",
    name: "AWSDataSyncFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTConfigReadOnlyAccess",
    description: "This policy gives read only access to the AWS IoT configuration actions",
    name: "AWSIoTConfigReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonWorkMailReadOnlyAccess",
    description: "Provides read only access to WorkMail and SES.",
    name: "AmazonWorkMailReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonDocDBConsoleFullAccess",
    description:
      "Provides full access to manage Amazon DocumentDB with MongoDB compatibility using the AWS Management Console. Note this policy also grants full access to publish on all SNS topics within the account, permissions to create and edit Amazon EC2 instances and VPC configurations, permissions to view and list keys on Amazon KMS, and full access to Amazon RDS and Amazon Neptune.",
    name: "AmazonDocDBConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ResourceGroupsandTagEditorReadOnlyAccess",
    description: "Provides access to use Resource Groups and Tag Editor, but does not allow editing of tags via the Tag Editor.",
    name: "ResourceGroupsandTagEditorReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ComprehendReadOnly",
    description: "Provides read-only access to Amazon Comprehend.",
    name: "ComprehendReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSStepFunctionsConsoleFullAccess",
    description:
      "An access policy for providing a user/role/etc access to the AWS StepFunctions console. For a full console experience, in addition to this policy, a user may need iam:PassRole permission on other IAM roles that can be assumed by the service.",
    name: "AWSStepFunctionsConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSQuickSightIoTAnalyticsAccess",
    description: "Give QuickSight read-only access to IoT Analytics datasets",
    name: "AWSQuickSightIoTAnalyticsAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodeBuildReadOnlyAccess",
    description:
      "Provides read only access to AWS CodeBuild via the AWS Management Console. Also attach AmazonS3ReadOnlyAccess to provide access to download build artifacts.",
    name: "AWSCodeBuildReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMacieFullAccess",
    description: "Provides full access to Amazon Macie.",
    name: "AmazonMacieFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMachineLearningManageRealTimeEndpointOnlyAccess",
    description: "Grants users permission to create and delete the real-time endpoint for Amazon Machine Learning models.",
    name: "AmazonMachineLearningManageRealTimeEndpointOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudFrontReadOnlyAccess",
    description:
      "Provides access to CloudFront distribution configuration information and list distributions via the AWS Management Console.",
    name: "CloudFrontReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMobileAnalyticsFinancialReportAccess",
    description: "Provides read only access to all reports including financial data for all application resources.",
    name: "AmazonMobileAnalyticsFinancialReportAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/IAMReadOnlyAccess",
    description: "Provides read only access to IAM via the AWS Management Console.",
    name: "IAMReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRDSReadOnlyAccess",
    description: "Provides read only access to Amazon RDS via the AWS Management Console.",
    name: "AmazonRDSReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonCognitoPowerUser",
    description:
      "Provides administrative access to existing Amazon Cognito resources. You will need AWS account admin privileges to create new Cognito resources.",
    name: "AmazonCognitoPowerUser",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRoute53AutoNamingRegistrantAccess",
    description: "Provides registrant level access to Route 53 Auto Naming actions.",
    name: "AmazonRoute53AutoNamingRegistrantAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElasticFileSystemFullAccess",
    description: "Provides full access to Amazon EFS via the AWS Management Console.",
    name: "AmazonElasticFileSystemFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ServerMigrationConnector",
    description:
      "Permissions to allow the AWS Server Migration Connector to migrate VMs to EC2. Allows communication with the AWS Server Migration Service, read/write access to S3 buckets starting with 'sms-b-' and 'import-to-ec2-' as well as the buckets used for AWS Server Migration Connector upgrade, AWS Server Migration Connector registration with AWS, and metrics upload to AWS.",
    name: "ServerMigrationConnector",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonESCognitoAccess",
    description: "Provides limited access to the Amazon Cognito configuration service.",
    name: "AmazonESCognitoAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSFMAdminFullAccess",
    description: "Full access for AWS FM Administrator",
    name: "AWSFMAdminFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonChimeReadOnly",
    description: "Provides read only access to Amazon Chime Admin Console via the AWS Management Console.",
    name: "AmazonChimeReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonZocaloFullAccess",
    description: "Provides full access to Amazon Zocalo.",
    name: "AmazonZocaloFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTSiteWiseReadOnlyAccess",
    description: "Provides read only access to IoT SiteWise.",
    name: "AWSIoTSiteWiseReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSAccountUsageReportAccess",
    description: "Allows users to access the Account Usage Report page.",
    name: "AWSAccountUsageReportAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMQFullAccess",
    description: "Provides full access to AmazonMQ via the AWS Management Console.",
    name: "AmazonMQFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMarketplaceGetEntitlements",
    description: "Provides read access to AWS Marketplace Entitlements",
    name: "AWSMarketplaceGetEntitlements",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSGreengrassReadOnlyAccess",
    description: "This policy gives read only access to the AWS Greengrass configuration, management and deployment actions",
    name: "AWSGreengrassReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonAppStreamFullAccess",
    description: "Provides full access to Amazon AppStream via the AWS Management Console.",
    name: "AmazonAppStreamFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTDataAccess",
    description: "This policy gives full access to the AWS IoT messaging actions",
    name: "AWSIoTDataAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonWorkLinkFullAccess",
    description: "Grants full access to Amazon WorkLink resources",
    name: "AmazonWorkLinkFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonTranscribeReadOnlyAccess",
    description: "Provides access to read only operation for Amazon Transcribe",
    name: "AmazonTranscribeReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonESFullAccess",
    description: "Provides full access to the Amazon ES configuration service.",
    name: "AmazonESFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSumerianFullAccess",
    description: "Provides full access to Amazon Sumerian.",
    name: "AmazonSumerianFullAccess",
  },
  { arn: "arn:aws:iam::aws:policy/AWSWAFFullAccess", description: "Provides full access to AWS WAF actions.", name: "AWSWAFFullAccess" },
  {
    arn: "arn:aws:iam::aws:policy/ElasticLoadBalancingReadOnly",
    description: "Provides read only access to Amazon ElasticLoadBalancing and dependent services",
    name: "ElasticLoadBalancingReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonKinesisFirehoseFullAccess",
    description: "Provides full access to all Amazon Kinesis Firehose Delivery Streams.",
    name: "AmazonKinesisFirehoseFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudWatchReadOnlyAccess",
    description: "Provides read only access to CloudWatch.",
    name: "CloudWatchReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ResourceGroupsandTagEditorFullAccess",
    description: "Provides full access to Resource Groups and Tag Editor.",
    name: "ResourceGroupsandTagEditorFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSKeyManagementServicePowerUser",
    description: "Provides access to AWS Key Management Service (KMS).",
    name: "AWSKeyManagementServicePowerUser",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSImportExportReadOnlyAccess",
    description: "Provides read only access to the jobs created under the AWS account.",
    name: "AWSImportExportReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSGlueConsoleFullAccess",
    description: "Provides full access to AWS Glue via the AWS Management Console",
    name: "AWSGlueConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDeviceFarmFullAccess",
    description: "Provides full access to all AWS Device Farm operations.",
    name: "AWSDeviceFarmFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSSMReadOnlyAccess",
    description: "Provides read only access to Amazon SSM.",
    name: "AmazonSSMReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSStepFunctionsReadOnlyAccess",
    description: "An access policy for providing a user/role/etc read only access to the AWS StepFunctions service.",
    name: "AWSStepFunctionsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMarketplaceRead-only",
    description: "Provides the ability to review AWS Marketplace subscriptions",
    name: "AWSMarketplaceRead-only",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodePipelineFullAccess",
    description: "Provides full access to AWS CodePipeline via the AWS Management Console.",
    name: "AWSCodePipelineFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCloud9User",
    description: "Provides permission to create AWS Cloud9 development environments and to manage owned environments.",
    name: "AWSCloud9User",
  },
  {
    arn: "arn:aws:iam::aws:policy/job-function/NetworkAdministrator",
    description: "Grants full access permissions to AWS services and actions required to set up and configure AWS network resources.",
    name: "NetworkAdministrator",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoT1ClickFullAccess",
    description: "Provides full access to AWS IoT 1-Click.",
    name: "AWSIoT1ClickFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonWorkSpacesApplicationManagerAdminAccess",
    description: "Provides administrator access for packaging an application in Amazon WorkSpaces Application Manager.",
    name: "AmazonWorkSpacesApplicationManagerAdminAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonDRSVPCManagement",
    description: "Provides access to manage VPC settings for Amazon managed customer configurations",
    name: "AmazonDRSVPCManagement",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCertificateManagerPrivateCAReadOnly",
    description: "Provides read only access to AWS Certificate Manager Private Certificate Authority",
    name: "AWSCertificateManagerPrivateCAReadOnly",
  },
  { arn: "arn:aws:iam::aws:policy/AWSXrayFullAccess", description: "AWS X-Ray full access managed policy", name: "AWSXrayFullAccess" },
  {
    arn: "arn:aws:iam::aws:policy/AWSElasticBeanstalkWorkerTier",
    description:
      "Provide the instances in your worker environment access to upload log files to Amazon S3, to use Amazon SQS to monitor your application's job queue, to use Amazon DynamoDB to perform leader election, and to Amazon CloudWatch to publish metrics for health monitoring. ",
    name: "AWSElasticBeanstalkWorkerTier",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDirectConnectFullAccess",
    description: "Provides full access to AWS Direct Connect via the AWS Management Console.",
    name: "AWSDirectConnectFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodeBuildAdminAccess",
    description:
      "Provides full access to AWS CodeBuild via the AWS Management Console. Also attach AmazonS3ReadOnlyAccess to provide access to download build artifacts, and attach IAMFullAccess to create and manage the service role for CodeBuild.",
    name: "AWSCodeBuildAdminAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonKinesisAnalyticsFullAccess",
    description: "Provides full access to Amazon Kinesis Analytics via the AWS Management Console.",
    name: "AmazonKinesisAnalyticsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSAccountActivityAccess",
    description: "Allows users to access the Account Activity page.",
    name: "AWSAccountActivityAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonGlacierFullAccess",
    description: "Provides full access to Amazon Glacier via the AWS Management Console.",
    name: "AmazonGlacierFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonFSxConsoleReadOnlyAccess",
    description: "Provides read only access to Amazon FSx and access to related AWS services via the AWS Management Console.",
    name: "AmazonFSxConsoleReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonWorkMailFullAccess",
    description: "Provides full access to WorkMail, Directory Service, SES, EC2 and read access to KMS metadata.",
    name: "AmazonWorkMailFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ComprehendMedicalFullAccess",
    description: "Provides full access to Amazon Comprehend Medical",
    name: "ComprehendMedicalFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMarketplaceManageSubscriptions",
    description: "Provides the ability to subscribe and unsubscribe to AWS Marketplace software",
    name: "AWSMarketplaceManageSubscriptions",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElasticBeanstalkCustomPlatformforEC2Role",
    description:
      "Provide the instance in your custom platform builder environment permission to launch EC2 instance, create EBS snapshot and AMI, stream logs to Amazon CloudWatch Logs, and store artifacts in Amazon S3.",
    name: "AWSElasticBeanstalkCustomPlatformforEC2Role",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDataSyncReadOnlyAccess",
    description: "Provides read-only access to AWS DataSync",
    name: "AWSDataSyncReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/NeptuneReadOnlyAccess",
    description:
      "Provides read only access to Amazon Neptune. Note that this policy also grants access to Amazon RDS resources. For more information, see https://aws.amazon.com/neptune/faqs/.",
    name: "NeptuneReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSSupportAccess",
    description: "Allows users to access the AWS Support Center.",
    name: "AWSSupportAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElementalMediaConvertReadOnly",
    description: "Provides read only access to AWS Elemental MediaConvert via the AWS Management Console and SDK.",
    name: "AWSElementalMediaConvertReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSLambdaInvocation-DynamoDB",
    description: "Provides read access to DynamoDB Streams.",
    name: "AWSLambdaInvocation-DynamoDB",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSServiceCatalogEndUserFullAccess",
    description: "Provides full access to service catalog enduser capabilities",
    name: "AWSServiceCatalogEndUserFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/IAMUserSSHKeys",
    description: "Provides the ability for an IAM user to manage their own SSH keys.",
    name: "IAMUserSSHKeys",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSageMakerReadOnly",
    description: "Provides read only access to Amazon SageMaker via the AWS Management Console and SDK.",
    name: "AmazonSageMakerReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTFullAccess",
    description: "This policy gives full access to the AWS IoT configuration and messaging actions",
    name: "AWSIoTFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonESReadOnlyAccess",
    description: "Provides read-only access to the Amazon ES configuration service.",
    name: "AmazonESReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodeDeployDeployerAccess",
    description: "Provides access to register and deploy a revision.",
    name: "AWSCodeDeployDeployerAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonPollyFullAccess",
    description: "Grants full access to Amazon Polly service and resources.",
    name: "AmazonPollyFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonLexFullAccess",
    description:
      "Provides full access to Amazon Lex via the AWS Management Console.  Also provides access to create Lex Service Linked Roles and grant Lex permissions to invoke a limited set of Lambda functions.",
    name: "AmazonLexFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonLexRunBotsOnly",
    description: "Provides access to Amazon Lex conversational APIs.",
    name: "AmazonLexRunBotsOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCertificateManagerPrivateCAAuditor",
    description: "Provides auditor access to AWS Certificate Manager Private Certificate Authority",
    name: "AWSCertificateManagerPrivateCAAuditor",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSNSFullAccess",
    description: "Provides full access to Amazon SNS via the AWS Management Console.",
    name: "AmazonSNSFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEKS_CNI_Policy",
    description:
      "This policy provides the Amazon VPC CNI Plugin (amazon-vpc-cni-k8s) the permissions it requires to modify the IP address configuration on your EKS worker nodes. This permission set allows the CNI to list, describe, and modify Elastic Network Interfaces on your behalf. More information on the AWS VPC CNI Plugin is available here: https://github.com/aws/amazon-vpc-cni-k8s",
    name: "AmazonEKS_CNI_Policy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSServiceCatalogAdminFullAccess",
    description: "Provides full access to service catalog admin capabilities",
    name: "AWSServiceCatalogAdminFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudSearchReadOnlyAccess",
    description: "Provides read only access to the Amazon CloudSearch configuration service.",
    name: "CloudSearchReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSGreengrassFullAccess",
    description: "This policy gives full access to the AWS Greengrass configuration, management and deployment actions",
    name: "AWSGreengrassFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/NeptuneConsoleFullAccess",
    description:
      "Provides full access to manage Amazon Neptune using the AWS Console. Note this policy also grants full access to publish on all SNS topics within the account, permissions to create and edit Amazon EC2 instances and VPC configurations, permissions to view and list keys on Amazon KMS, and full access to Amazon RDS. For more information, see https://aws.amazon.com/neptune/faqs/.",
    name: "NeptuneConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCloudFormationReadOnlyAccess",
    description: "Provides access to AWS CloudFormation via the AWS Management Console.",
    name: "AWSCloudFormationReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRoute53FullAccess",
    description: "Provides full access to all Amazon Route 53 via the AWS Management Console.",
    name: "AmazonRoute53FullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSOpsWorksCloudWatchLogs",
    description: "Enables OpsWorks instances with the CWLogs integration enabled to ship logs and create required log groups",
    name: "AWSOpsWorksCloudWatchLogs",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonAppStreamReadOnlyAccess",
    description: "Provides read only access to Amazon AppStream via the AWS Management Console.",
    name: "AmazonAppStreamReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSStepFunctionsFullAccess",
    description:
      "An access policy for providing a user/role/etc access to the AWS StepFunctions API. For full access, in addition to this policy, a user MUST have iam:PassRole permission on at least one IAM role that can be assumed by the service.",
    name: "AWSStepFunctionsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonInspectorReadOnlyAccess",
    description: "Provides read only access to Amazon Inspector.",
    name: "AmazonInspectorReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSOrganizationsReadOnlyAccess",
    description: "Provides read-only access to AWS Organizations.",
    name: "AWSOrganizationsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/TranslateReadOnly",
    description: "Provides read-only access to Amazon Translate.",
    name: "TranslateReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCertificateManagerFullAccess",
    description: "Provides full access to AWS Certificate Manager (ACM)",
    name: "AWSCertificateManagerFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDeepRacerCloudFormationAccessPolicy",
    description: "Allows CloudFormation to create and manage AWS stacks and resources on your behalf.",
    name: "AWSDeepRacerCloudFormationAccessPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTEventsReadOnlyAccess",
    description: "Provides read only access to IoT Events.",
    name: "AWSIoTEventsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/PowerUserAccess",
    description: "Provides full access to AWS services and resources, but does not allow management of Users and groups.",
    name: "PowerUserAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/GlobalAcceleratorReadOnlyAccess",
    description: "Allow GlobalAccelerator Users Access to Read Only APIs",
    name: "GlobalAcceleratorReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSageMakerFullAccess",
    description:
      "Provides full access to Amazon SageMaker via the AWS Management Console and SDK. Also provides select access to related services (e.g., S3, ECR, CloudWatch Logs).",
    name: "AmazonSageMakerFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElementalMediaStoreFullAccess",
    description: "Provides full read and write access to all MediaStore APIs",
    name: "AWSElementalMediaStoreFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudWatchEventsFullAccess",
    description: "Provides full access to Amazon CloudWatch Events.",
    name: "CloudWatchEventsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSOrganizationsFullAccess",
    description: "Provides full access to AWS Organizations.",
    name: "AWSOrganizationsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodePipeline_FullAccess",
    description: "Provides full access to AWS CodePipeline via the AWS Management Console.",
    name: "AWSCodePipeline_FullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonAugmentedAIIntegratedAPIAccess",
    description:
      "Provides access to perform all operations Amazon Augmented AI resources, including FlowDefinitions, HumanTaskUis and HumanLoops. Also provides access to those operations of services that are integrated with Amazon Augmented AI. ",
    name: "AmazonAugmentedAIIntegratedAPIAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElasticDisasterRecoveryReadOnlyAccess",
    description:
      "You can attach the AWSElasticDisasterRecoveryReadOnlyAccess policy to your IAM identities.  This policy provides permissions to all read-only public APIs of Elastic Disaster Recovery (DRS), as well as some read-only APIs of other AWS services that are required in order to make full read-only use of the DRS console. Attach this policy to your IAM users or roles.",
    name: "AWSElasticDisasterRecoveryReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonFraudDetectorFullAccessPolicy",
    description: "Gives access to all actions for Amazon Fraud Detector",
    name: "AmazonFraudDetectorFullAccessPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonLaunchWizard_Fullaccess",
    description: "Full access to AWS Launch wizard and other required services.",
    name: "AmazonLaunchWizard_Fullaccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonChimeSDK",
    description: "Provides access to Amazon Chime SDK operations",
    name: "AmazonChimeSDK",
  },
  {
    arn: "arn:aws:iam::aws:policy/AwsGlueDataBrewFullAccessPolicy",
    description:
      "Provides full access to AWS Glue DataBrew via the AWS Management Console. Also provides select access to related services (e.g., S3, KMS, Glue).",
    name: "AwsGlueDataBrewFullAccessPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElasticContainerRegistryPublicReadOnly",
    description: "Provides read-only access to Amazon ECR Public repositories.",
    name: "AmazonElasticContainerRegistryPublicReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTDeviceTesterForFreeRTOSFullAccess",
    description:
      "Allows AWS IoT Device Tester to run the FreeRTOS qualification suite by allowing access to services including IoT, S3, and IAM",
    name: "AWSIoTDeviceTesterForFreeRTOSFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSBudgetsActionsWithAWSResourceControlAccess",
    description:
      "Provides full access to AWS Budgets Actions including using Budgets Actions to control states of running AWS resources via AWS Management Console",
    name: "AWSBudgetsActionsWithAWSResourceControlAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElasticBeanstalkManagedUpdatesCustomerRolePolicy",
    description:
      "This policy is for the AWS Elastic Beanstalk service role used to perform managed updates of Elastic Beanstalk environments. This policy should not be attached to other users or roles. The policy grants broad permissions to create and manage resources across a number of AWS services including AutoScaling, EC2, ECS, Elastic Load Balancing and CloudFormation.  This policy also allows passing of any IAM role usable with those services.",
    name: "AWSElasticBeanstalkManagedUpdatesCustomerRolePolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AdministratorAccess-Amplify",
    description:
      "Grants account administrative permissions while explicitly allowing direct access to resources needed by Amplify applications.",
    name: "AdministratorAccess-Amplify",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRoute53RecoveryReadinessFullAccess",
    description: "Provides full access to Amazon Route 53 Recovery Readiness",
    name: "AmazonRoute53RecoveryReadinessFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSNetworkManagerFullAccess",
    description: "Provides full access to Amazon NetworkManager via the AWS Management Console.",
    name: "AWSNetworkManagerFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMarketplaceAmiIngestion",
    description: "Allows AWS Marketplace to copy your Amazon Machine Images (AMIs) in order to list them on AWS Marketplace",
    name: "AWSMarketplaceAmiIngestion",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSPrivateMarketplaceRequests",
    description: "Provides access to creating requests in an AWS Private Marketplace.",
    name: "AWSPrivateMarketplaceRequests",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudWatchApplicationInsightsReadOnlyAccess",
    description: "Provides read only access to CloudWatch Application Insights. ",
    name: "CloudWatchApplicationInsightsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AdministratorAccess-AWSElasticBeanstalk",
    description:
      "Grants account administrative permissions. Explicitly allows developers and administrators to gain direct access to resources they need to manage AWS Elastic Beanstalk applications",
    name: "AdministratorAccess-AWSElasticBeanstalk",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSageMakerMechanicalTurkAccess",
    description: "Provides access to create Amazon Augmented AI FlowDefinition resources against any Workteam.",
    name: "AmazonSageMakerMechanicalTurkAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonTimestreamConsoleFullAccess",
    description:
      "Provides full access to manage Amazon Timestream using the AWS Management Console. Note that this policy also grants permissions for certain KMS operations, and operations to manage your saved queries. If using Customer managed CMK, please refer to documentation for additional permissions needed.",
    name: "AmazonTimestreamConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSWAFConsoleFullAccess",
    description:
      "Provides full access to AWS WAF via the AWS Management Console. Note that this policy also grants permissions to list and update Amazon CloudFront distributions, permissions to view load balancers on AWS Elastic Load Balancing, permissions to view Amazon API Gateway REST APIs and stages, permissions to list and view Amazon CloudWatch metrics, and permissions to view regions enabled within the account.",
    name: "AWSWAFConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSGlueSchemaRegistryReadonlyAccess",
    description: "Provides readonly access to the AWS Glue Schema Registry Service",
    name: "AWSGlueSchemaRegistryReadonlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEventBridgeReadOnlyAccess",
    description: "Provides read only access to Amazon EventBridge.",
    name: "AmazonEventBridgeReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElementalMediaTailorReadOnly",
    description: "Provides read only access to AWS Elemental MediaTailor resources",
    name: "AWSElementalMediaTailorReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonKendraFullAccess",
    description: "Provides full access to Amazon Kendra via the AWS Management Console.",
    name: "AmazonKendraFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEMRFullAccessPolicy_v2",
    description: "Provides full access to Amazon EMR",
    name: "AmazonEMRFullAccessPolicy_v2",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonS3OutpostsFullAccess",
    description: "Provides full access to Amazon S3 on Outposts via the AWS Management Console.",
    name: "AmazonS3OutpostsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRedshiftQueryEditorV2FullAccess",
    description:
      "Grants full access to the Amazon Redshift Query Editor V2 operations and resources. This policy also grants access to other required services. This includes permissions to list the Amazon Redshift clusters, read keys and aliases in AWS KMS and manage the Query Editor V2 secrets in AWS Secrets Manager.",
    name: "AmazonRedshiftQueryEditorV2FullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRoute53RecoveryControlConfigFullAccess",
    description: "Provides full access to Amazon Route 53 Recovery Control Config",
    name: "AmazonRoute53RecoveryControlConfigFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonDevOpsGuruFullAccess",
    description: "Provides full access to Amazon DevOps Guru.",
    name: "AmazonDevOpsGuruFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSResourceAccessManagerReadOnlyAccess",
    description: "Provides read only access to AWS Resource Access Manager.",
    name: "AWSResourceAccessManagerReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEventBridgeFullAccess",
    description: "Provides full access to Amazon EventBridge.",
    name: "AmazonEventBridgeFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSThinkboxAWSPortalAdminPolicy",
    description:
      "This policy grants AWS Thinkbox's Deadline software full access to multiple AWS services as required for AWS Portal administration. This includes access to create arbitrary tags on several EC2 resource types.",
    name: "AWSThinkboxAWSPortalAdminPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElasticBeanstalkReadOnly",
    description:
      "Grants read-only permissions. Explicitly allows operators to gain direct access to retrieve information about resources related to AWS Elastic Beanstalk applications.",
    name: "AWSElasticBeanstalkReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/EC2InstanceProfileForImageBuilderECRContainerBuilds",
    description:
      "EC2 Instance profile for building container images with EC2 Image Builder. This policy grants the user broad permissions to upload ECR images.",
    name: "EC2InstanceProfileForImageBuilderECRContainerBuilds",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSBackupAuditAccess",
    description:
      "This policy grants permissions for users to create controls and frameworks that define their expectations for AWS Backup resources and activities, and to audit AWS Backup resources and activities against their defined controls and frameworks. This policy grants permissions to AWS Config and similar services to describe user expectations perform the audits. This policy also grants permissions to deliver audit reports to S3 and similar services, and enables users to find and open their audit reports.",
    name: "AWSBackupAuditAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudWatchSyntheticsReadOnlyAccess",
    description: "Provides read only access to CloudWatch Synthetics.",
    name: "CloudWatchSyntheticsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonNimbleStudio-StudioUser",
    description:
      "This policy grants access to Amazon Nimble Studio resources associated with the studio user and related studio resources in other services. Attach this policy to the User role associated with your studio.",
    name: "AmazonNimbleStudio-StudioUser",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCloudTrail_FullAccess",
    description: "Provides full access to AWS CloudTrail.",
    name: "AWSCloudTrail_FullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRoute53ResolverReadOnlyAccess",
    description: "Read only policy for Route 53 Resolver",
    name: "AmazonRoute53ResolverReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonBraketJobsExecutionPolicy",
    description:
      "Grants access to AWS Services and resources necessary for executing an Amazon Braket Job including S3, Cloudwatch, IAM and Braket",
    name: "AmazonBraketJobsExecutionPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEC2RolePolicyForLaunchWizard",
    description: "Managed policy for the Amazon LaunchWizard service role for EC2",
    name: "AmazonEC2RolePolicyForLaunchWizard",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonAppFlowReadOnlyAccess",
    description: "Provides read only access to Amazon Appflow flows",
    name: "AmazonAppFlowReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonLookoutVisionConsoleReadOnlyAccess",
    description: "Provides read only access to Amazon Lookout for Vision and scoped access to required service and console dependencies.",
    name: "AmazonLookoutVisionConsoleReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonManagedBlockchainFullAccess",
    description: "Provides full access to Amazon Managed Blockchain.",
    name: "AmazonManagedBlockchainFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ServiceQuotasFullAccess",
    description: "Provides full access to Service Quotas",
    name: "ServiceQuotasFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonTimestreamFullAccess",
    description:
      "Provides full access to Amazon Timestream. Note that this policy also grants certain KMS operation access. If using Customer managed CMK, please refer to documentation for additional permissions needed.",
    name: "AmazonTimestreamFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSBackupServiceRolePolicyForS3Backup",
    description:
      "Policy containing permissions necessary for AWS Backup to backup data in any S3 bucket. This includes read access to all S3 objects and any decrypt access for all KMS keys.",
    name: "AWSBackupServiceRolePolicyForS3Backup",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSAppRunnerFullAccess",
    description: "Grants permissions to all App Runner actions.",
    name: "AWSAppRunnerFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonInspector2FullAccess",
    description: "Provides full access to Amazon Inspector and access to other related services such as organizations.",
    name: "AmazonInspector2FullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ElementalAppliancesSoftwareReadOnlyAccess",
    description: "Read-only access to view Elemental Appliances and Software quotes and orders",
    name: "ElementalAppliancesSoftwareReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonLookoutVisionFullAccess",
    description: "Provides full access to Amazon Lookout for Vision and scoped access to required dependencies.",
    name: "AmazonLookoutVisionFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSBugBustPlayerAccess",
    description: "This IAM policy grants users access to participate in AWS BugBust events",
    name: "AWSBugBustPlayerAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonHoneycodeReadOnlyAccess",
    description: "Provides read only access to Honeycode via the AWS Management Console and the SDK.",
    name: "AmazonHoneycodeReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCloudFormationFullAccess",
    description: "Provides full access to AWS CloudFormation.",
    name: "AWSCloudFormationFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonLookoutMetricsFullAccess",
    description: "Gives access to all actions for Amazon Lookout for Metrics",
    name: "AmazonLookoutMetricsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSApplicationMigrationAgentPolicy",
    description:
      "This policy allows installing and using the AWS Replication Agent, which is used with AWS Application Migration Service (MGN) to migrate external servers to AWS. Attach this policy to your IAM users or roles whose credentials you provide when installing the AWS Replication Agent.",
    name: "AWSApplicationMigrationAgentPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSOpsWorks_FullAccess",
    description: "Provides full access to AWS OpsWorks.",
    name: "AWSOpsWorks_FullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRoute53RecoveryControlConfigReadOnlyAccess",
    description: "Provides read only access to Amazon Route 53 Recovery Control Config",
    name: "AmazonRoute53RecoveryControlConfigReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ElementalAppliancesSoftwareFullAccess",
    description: "Full access to view and take action on Elemental Appliances and Software quotes and orders",
    name: "ElementalAppliancesSoftwareFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonAugmentedAIHumanLoopFullAccess",
    description: "Provides access to perform all operations on HumanLoops.",
    name: "AmazonAugmentedAIHumanLoopFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonLookoutEquipmentReadOnlyAccess",
    description: "Provides read only access to Amazon Lookout for Equipments",
    name: "AmazonLookoutEquipmentReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonCloudWatchRUMFullAccess",
    description: "Grants full access permissions for the Amazon CloudWatch RUM service",
    name: "AmazonCloudWatchRUMFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDataExchangeReadOnly",
    description: "Grants read-only access to AWS Data Exchange and AWS Marketplace actions using the AWS Management Console and SDK.",
    name: "AWSDataExchangeReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMarketplaceSellerProductsFullAccess",
    description: "Provides sellers full access to AWS Marketplace Management Products page and other AWS services such as AMI management.",
    name: "AWSMarketplaceSellerProductsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSProtonReadOnlyAccess",
    description: "Provides read only access to the AWS Proton APIs and Management Console.",
    name: "AWSProtonReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSBackupOrganizationAdminAccess",
    description: "This policy is for backup administators who use cross-account backup management to manage backups for the organization.",
    name: "AWSBackupOrganizationAdminAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSAuditManagerAdministratorAccess",
    description:
      "Provides administrative access to enable or disable AWS Audit Manager, update settings, and manage assessments, controls, and frameworks",
    name: "AWSAuditManagerAdministratorAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ElementalSupportCenterFullAccess",
    description: "Full access to view and take action on Elemental Appliance and Software support cases and product support content",
    name: "ElementalSupportCenterFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonHoneycodeFullAccess",
    description: "Provides full access to Honeycode via the AWS Management Console and the SDK.",
    name: "AmazonHoneycodeFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonWorkDocsReadOnlyAccess",
    description: "Provides read only access to Amazon WorkDocs via the AWS Management Console",
    name: "AmazonWorkDocsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudWatchLambdaInsightsExecutionRolePolicy",
    description: "Policy required for the Lambda Insights Extension",
    name: "CloudWatchLambdaInsightsExecutionRolePolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonConnectVoiceIDFullAccess",
    description: "Provides full access to Amazon Connect Voice ID",
    name: "AmazonConnectVoiceIDFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/EC2InstanceProfileForImageBuilder",
    description: "EC2 Instance profile for Image Builder service.",
    name: "EC2InstanceProfileForImageBuilder",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonCodeGuruReviewerFullAccess",
    description: "Grants full access to Amazon CodeGuru Reviewer and scoped access to required dependencies.",
    name: "AmazonCodeGuruReviewerFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSImageBuilderFullAccess",
    description: "Provides full access to all AWS Image Builder actions and resource scoped access to related AWS services.",
    name: "AWSImageBuilderFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIncidentManagerResolverAccess",
    description:
      "This policy grants permissions to start, view, and update incidents with full access to custom timeline events & related items. Assign this policy to users who will create and resolve incidents.",
    name: "AWSIncidentManagerResolverAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCertificateManagerPrivateCAPrivilegedUser",
    description: "Provides privileged certificate user access to AWS Certificate Manager Private Certificate Authority",
    name: "AWSCertificateManagerPrivateCAPrivilegedUser",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonCloudWatchEvidentlyReadOnlyAccess",
    description: "Provides read only access to Amazon CloudWatch Evidently",
    name: "AmazonCloudWatchEvidentlyReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSSMPatchAssociation",
    description: "Provide access to child instances for patch association operation.",
    name: "AmazonSSMPatchAssociation",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSBudgetsReadOnlyAccess",
    description: "Provides read only access to AWS Budgets Console via the AWS Management Console.",
    name: "AWSBudgetsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSOpsWorksRegisterCLI_OnPremises",
    description: "Policy to enable registration of On-Premises instances via the OpsWorks CLI",
    name: "AWSOpsWorksRegisterCLI_OnPremises",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSApplicationMigrationVCenterClientPolicy",
    description:
      "This policy allows installing and using the AWS VCenter Client, which is used with AWS Application Migration Service (MGN) to migrate external servers to AWS. Attach this policy to your IAM users or roles whose credentials you provide when installing the AWS VCenter Client.",
    name: "AWSApplicationMigrationVCenterClientPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElasticContainerRegistryPublicFullAccess",
    description: "Provides administrative access to Amazon ECR Public resources",
    name: "AmazonElasticContainerRegistryPublicFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMCSReadOnlyAccess",
    description: "Provide read only access to Amazon Managed Apache Cassandra Service",
    name: "AmazonMCSReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonOpenSearchServiceFullAccess",
    description: "Provides full access to the Amazon OpenSearch Service configuration service.",
    name: "AmazonOpenSearchServiceFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSRoboMaker_FullAccess",
    description:
      "Provides full access to AWS RoboMaker via the AWS Management Console and SDK. Also provides select access to related services (e.g., S3, IAM).",
    name: "AWSRoboMaker_FullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ComputeOptimizerReadOnlyAccess",
    description: "Provides read only access to ComputeOptimizer.",
    name: "ComputeOptimizerReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AlexaForBusinessPolyDelegatedAccessPolicy",
    description: "Provide access to Poly AVS devices",
    name: "AlexaForBusinessPolyDelegatedAccessPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMarketplaceProcurementSystemAdminFullAccess",
    description: "Provides full access to all administrative actions for an AWS Marketplace eProcurement integration.",
    name: "AWSMarketplaceProcurementSystemAdminFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSAccountManagementReadOnlyAccess",
    description: "Provides read-only access to AWS Account Management",
    name: "AWSAccountManagementReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEKSFargatePodExecutionRolePolicy",
    description: "Provides access to other AWS service resources that are required to run Amazon EKS pods on AWS Fargate",
    name: "AmazonEKSFargatePodExecutionRolePolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTWirelessReadOnlyAccess",
    description: "Allows the associated identity read only access to AWS IoT wireless.",
    name: "AWSIoTWirelessReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSageMakerPipelinesIntegrations",
    description:
      "This Amazon Managed Policy grants permissions commonly needed for use with Callback steps and Lambda steps in SageMaker Model Building Pipelines. It is added to the AmazonSageMaker-ExecutionRole that can be created when setting up SageMaker Studio. It can also be attached to any other role that will be used for authoring or executing pipelines.",
    name: "AmazonSageMakerPipelinesIntegrations",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSThinkboxDeadlineResourceTrackerAdminPolicy",
    description: "Grants permissions required to create, destroy, and administer AWS Thinkbox's Deadline Resource Tracker.",
    name: "AWSThinkboxDeadlineResourceTrackerAdminPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRedshiftAllCommandsFullAccess",
    description:
      "This policy includes permissions to run SQL commands to copy, load, unload, query, and analyze data on Amazon Redshift. The policy also grants permissions to run select statements for related services, such as Amazon S3, Amazon CloudWatch logs, Amazon SageMaker, or AWS Glue.",
    name: "AmazonRedshiftAllCommandsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/IAMAccessAdvisorReadOnly",
    description:
      "This policy grants access to read all access information provided by IAM access advisor such as service last accessed information.",
    name: "IAMAccessAdvisorReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSageMakerFeatureStoreAccess",
    description: "Provides permissions required to enable the offline store for an Amazon SageMaker FeatureStore feature group.",
    name: "AmazonSageMakerFeatureStoreAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonCodeGuruReviewerReadOnlyAccess",
    description: "Provides read only access to Amazon CodeGuru Reviewer.",
    name: "AmazonCodeGuruReviewerReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSThinkboxAWSPortalGatewayPolicy",
    description: "This policy grants the AWS Portal Gateway machine the necessary permissions required for normal operation.",
    name: "AWSThinkboxAWSPortalGatewayPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonCodeGuruProfilerFullAccess",
    description: "Provides full access to Amazon CodeGuru Profiler.",
    name: "AmazonCodeGuruProfilerFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSProtonDeveloperAccess",
    description:
      "Provides access to the AWS Proton APIs and Management Console, but does not allow administration of Proton templates or environments.",
    name: "AWSProtonDeveloperAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSageMakerGroundTruthExecution",
    description: "Provides access to AWS services that are required to run SageMaker GroundTruth Labeling job",
    name: "AmazonSageMakerGroundTruthExecution",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSResourceAccessManagerFullAccess",
    description: "Provides full access to AWS Resource Access Manager",
    name: "AWSResourceAccessManagerFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSGrafanaWorkspacePermissionManagement",
    description: "Provides only the ability to update user and group permissions for AWS Grafana workspaces.",
    name: "AWSGrafanaWorkspacePermissionManagement",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonNimbleStudio-LaunchProfileWorker",
    description:
      "This policy grants access to resources needed by Nimble Studio Launch Profile workers. Attach this policy to EC2 instances created by Nimble Studio Builder.",
    name: "AmazonNimbleStudio-LaunchProfileWorker",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRedshiftQueryEditorV2ReadSharing",
    description:
      "Grants the ability to work with Amazon Redshift Query Editor V2 with limited sharing of resources. The granted principal can read, write and share its own resources. The granted principal can read the resources shared with its team but cannot update them. This policy also grants access to other required services. This includes permissions to list the Amazon Redshift clusters and manage the Query Editor V2 secrets of the principal in AWS Secrets Manager.",
    name: "AmazonRedshiftQueryEditorV2ReadSharing",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEMRReadOnlyAccessPolicy_v2",
    description: "Provides read only access to Amazon EMR and the associated CloudWatch Metrics.",
    name: "AmazonEMRReadOnlyAccessPolicy_v2",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonPrometheusQueryAccess",
    description: "Grants access to run queries against AWS Managed Prometheus resources",
    name: "AmazonPrometheusQueryAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonDevOpsGuruConsoleFullAccess",
    description: "The policy grants full-access to the DevOps Guru console.",
    name: "AmazonDevOpsGuruConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonWorkDocsFullAccess",
    description: "Provides full access to Amazon WorkDocs via the AWS Management Console",
    name: "AmazonWorkDocsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonHoneycodeWorkbookReadOnlyAccess",
    description: "Provides read only access to Honeycode Workbook via the AWS Management Console and the SDK.",
    name: "AmazonHoneycodeWorkbookReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/IAMAccessAnalyzerReadOnlyAccess",
    description: "Provides read only access to IAM Access Analyzer resources",
    name: "IAMAccessAnalyzerReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonQLDBConsoleFullAccess",
    description: "Provides full access to Amazon QLDB via the AWS Management Console.",
    name: "AmazonQLDBConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSGlueSchemaRegistryFullAccess",
    description: "Provides full access to the AWS Glue Schema Registry Service",
    name: "AWSGlueSchemaRegistryFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElasticFileSystemClientReadWriteAccess",
    description: "Provides read and write client access to an Amazon EFS file system",
    name: "AmazonElasticFileSystemClientReadWriteAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCloudShellFullAccess",
    description: "Grants using AWS CloudShell with all features",
    name: "AWSCloudShellFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMemoryDBReadOnlyAccess",
    description: "Provides read only access to Amazon MemoryDB via the AWS Management Console.",
    name: "AmazonMemoryDBReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTWirelessDataAccess",
    description: "Allows the associated identity data access to AWS IoT Wireless devices.",
    name: "AWSIoTWirelessDataAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonQLDBFullAccess",
    description: "Provides full access to Amazon QLDB via the service API.",
    name: "AmazonQLDBFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonWorkSpacesWebReadOnly",
    description:
      "Provides read-only access to Amazon WorkSpaces Web and its dependencies through the AWS Management Console, SDK, and CLI.",
    name: "AmazonWorkSpacesWebReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonAugmentedAIFullAccess",
    description:
      "Provides access to perform all operations Amazon Augmented AI resources, including FlowDefinitions, HumanTaskUis and HumanLoops. Does not allow access for creating FlowDefinitions against the public-crowd Workteam.",
    name: "AmazonAugmentedAIFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDeepRacerAccountAdminAccess",
    description: "DeepRacer admin access to all actions including toggling between multiuser and single user mode.",
    name: "AWSDeepRacerAccountAdminAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonKeyspacesFullAccess",
    description: "Provide full access to Amazon Keyspaces",
    name: "AmazonKeyspacesFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSAppMeshReadOnly",
    description: "Provides read-only access to the AWS App Mesh APIs and Management Console.",
    name: "AWSAppMeshReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSApplicationMigrationFullAccess",
    description:
      "This policy provides permissions to all public APIs of AWS Application Migration Service (MGN), as well as permissions to read KMS key information. Attach this policy to your IAM users or roles.",
    name: "AWSApplicationMigrationFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSLakeFormationCrossAccountManager",
    description:
      "Provides cross account access to Glue resources via Lake Formation. Also grants read access to other required services such as organizations and resource access manager",
    name: "AWSLakeFormationCrossAccountManager",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonBraketFullAccess",
    description:
      "Provides full access to Amazon Braket via the AWS Management Console and SDK. Also provides access to related services (e.g., S3, logs).",
    name: "AmazonBraketFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AlexaForBusinessLifesizeDelegatedAccessPolicy",
    description: "Provide access to Lifesize AVS devices",
    name: "AlexaForBusinessLifesizeDelegatedAccessPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonOpenSearchServiceCognitoAccess",
    description: "Provides access to the Amazon Cognito configuration service.",
    name: "AmazonOpenSearchServiceCognitoAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonTimestreamReadOnlyAccess",
    description:
      "Provides read only access to Amazon Timestream. Policy also provides permission to cancel any running query. If using Customer managed CMK, please refer to documentation for additional permissions needed.",
    name: "AmazonTimestreamReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSPanoramaFullAccess",
    description: "Provides full access to AWS Panorama",
    name: "AWSPanoramaFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonInspector2ReadOnlyAccess",
    description: "Provides read only access to the Amazon inspector2 service and relevant support services",
    name: "AmazonInspector2ReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonQLDBReadOnly",
    description: "Provides read only access to Amazon QLDB.",
    name: "AmazonQLDBReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDeepRacerDefaultMultiUserAccess",
    description: "DeepRacer MultiUser Default user access to use deepracer in multi-user mode",
    name: "AWSDeepRacerDefaultMultiUserAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSLambda_ReadOnlyAccess",
    description: "Grants read-only access to AWS Lambda service, AWS Lambda console features, and other related AWS services.",
    name: "AWSLambda_ReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodePipeline_ReadOnlyAccess",
    description: "Provides read only access to AWS CodePipeline via the AWS Management Console.",
    name: "AWSCodePipeline_ReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ServerMigrationServiceConsoleFullAccess",
    description: "Required permissions to use all features of the Server Migration Service Console",
    name: "ServerMigrationServiceConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSAppMeshFullAccess",
    description: "Provides full access to the AWS App Mesh APIs and Management Console.",
    name: "AWSAppMeshFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSProtonFullAccess",
    description:
      "Provides full access to the AWS Proton APIs and Management Console. In addition to these permissions, access to Amazon S3 is also needed to register template bundles from your S3 buckets, as well as access to Amazon IAM to create and manage the service roles for Proton.",
    name: "AWSProtonFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCloud9SSMInstanceProfile",
    description:
      "This policy will be used to attach a role on a InstanceProfile which will allow Cloud9 to use the SSM Session Manager to connect to the instance",
    name: "AWSCloud9SSMInstanceProfile",
  },
  {
    arn: "arn:aws:iam::aws:policy/ElementalActivationsDownloadSoftwareAccess",
    description: "Access to view purchased assets and download related software and kickstart files",
    name: "ElementalActivationsDownloadSoftwareAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonDetectiveFullAccess",
    description: " Provides full access to Amazon Detective service and scoped access to the console UI dependencies",
    name: "AmazonDetectiveFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSTransferReadOnlyAccess",
    description: "Provide readonly access to AWS Transfer services.",
    name: "AWSTransferReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ServiceQuotasReadOnlyAccess",
    description: "Provides read only access to Service Quotas",
    name: "ServiceQuotasReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSServiceCatalogEndUserReadOnlyAccess",
    description: "Provides read-only access to Service Catalog end-user capabilities ",
    name: "AWSServiceCatalogEndUserReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIotRoboRunnerFullAccess",
    description: "This policy grants permissions that allow full access to AWS Iot RoboRunner.",
    name: "AWSIotRoboRunnerFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ElementalActivationsFullAccess",
    description: "Full access to view and take action on Elemental Appliances and Software purchased assets",
    name: "ElementalActivationsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ElementalActivationsReadOnlyAccess",
    description: "Read-only access to the detailed list of purchased assets associated to the AWS account of the user",
    name: "ElementalActivationsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEventBridgeSchemasFullAccess",
    description: "Provides full access to Amazon EventBridge Schemas.",
    name: "AmazonEventBridgeSchemasFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMarketplaceSellerFullAccess",
    description: "Provides full access to all seller operations on the AWS Marketplace and other AWS services such as AMI management.",
    name: "AWSMarketplaceSellerFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRedshiftQueryEditorV2NoSharing",
    description:
      "Grants the ability to work with Amazon Redshift Query Editor V2 without sharing resources. The granted principal can only read, update and delete its own resources but cannot share them. This policy also grants access to other required services. This includes permissions to list the Amazon Redshift clusters and manage the Query Editor V2 secrets of the principal in AWS Secrets Manager.",
    name: "AmazonRedshiftQueryEditorV2NoSharing",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudWatchAutomaticDashboardsAccess",
    description:
      "Provides access to the non-CloudWatch APIs used to display CloudWatch Automatic Dashboards, including the contents of objects such as Lambda functions",
    name: "CloudWatchAutomaticDashboardsAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDeepRacerFullAccess",
    description: "Provides full access to AWS DeepRacer. Also provides select access to related services (e.g., S3).",
    name: "AWSDeepRacerFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonHoneycodeTeamAssociationFullAccess",
    description: "Provides full access to Honeycode Team Association via the AWS Management Console and the SDK. ",
    name: "AmazonHoneycodeTeamAssociationFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonPrometheusRemoteWriteAccess",
    description: "Grants write only access to AWS Managed Prometheus workspaces",
    name: "AmazonPrometheusRemoteWriteAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonDevOpsGuruReadOnlyAccess",
    description: "Provides read only access to Amazon DevOps Guru Console.",
    name: "AmazonDevOpsGuruReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEventBridgeSchemasReadOnlyAccess",
    description: "Provides read only access to Amazon EventBridge Schemas.",
    name: "AmazonEventBridgeSchemasReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSThinkboxDeadlineSpotEventPluginWorkerPolicy",
    description: "Grant permissions required for an EC2 instance running AWS Thinkbox Deadline Spot Event Plugin Worker software.",
    name: "AWSThinkboxDeadlineSpotEventPluginWorkerPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTWirelessFullPublishAccess",
    description: "Provides IoT Wireless full access to publish to IoT Rules Engine on your behalf.",
    name: "AWSIoTWirelessFullPublishAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/GameLiftGameServerGroupPolicy",
    description: "Policy to allow Gamelift GameServerGroups to manage customer resources",
    name: "GameLiftGameServerGroupPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElasticDisasterRecoveryFailbackInstallationPolicy",
    description:
      "You can attach the AWSElasticDisasterRecoveryFailbackInstallationPolicy policy to your IAM identities.  This policy allows installing the Elastic Disaster Recovery Failback Client, which is used to failback Recovery Instances back to your original source infrastructure. Attach this policy to your IAM users or roles whose credentials you provide when running the Elastic Disaster Recovery Failback Client.",
    name: "AWSElasticDisasterRecoveryFailbackInstallationPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonConnect_FullAccess",
    description:
      "The purpose of this policy is to grant permissions to AWS Connect users required to use Connect resources. This policy provides full access to AWS Connect resources via the Connect Console and public APIs",
    name: "AmazonConnect_FullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElementalMediaLiveFullAccess",
    description: "Provides full access to AWS Elemental MediaLive resources",
    name: "AWSElementalMediaLiveFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMarketplaceSellerProductsReadOnly",
    description: "Provide sellers read-only access to AWS Marketplace Management Products page.",
    name: "AWSMarketplaceSellerProductsReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMCSFullAccess",
    description: "Provide full access to Amazon Managed Apache Cassandra Service",
    name: "AmazonMCSFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTSiteWiseConsoleFullAccess",
    description:
      "Provides full access to manage AWS IoT SiteWise using the AWS Management Console. Note this policy also grants access to create and list data stores used with AWS IoT SiteWise (e.g. AWS IoT Analytics), access to list and view AWS IoT Greengrass resources, list and modify AWS Secrets Manager secrets, retrieve AWS IoT thing shadows, list resources with specific tags, and create and use a service-linked role for AWS IoT SiteWise.",
    name: "AWSIoTSiteWiseConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElasticFileSystemClientFullAccess",
    description: "Provides root client access to an Amazon EFS file system",
    name: "AmazonElasticFileSystemClientFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSThinkboxAssetServerPolicy",
    description: "This policy grants the AWS Portal Asset Server the necessary permissions required for normal operation.",
    name: "AWSThinkboxAssetServerPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSForWordPressPluginPolicy",
    description: "Managed policy for AWS For Wordpress Plugin",
    name: "AWSForWordPressPluginPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSTransferFullAccess",
    description: "Provides full access to AWS Transfer Service.",
    name: "AWSTransferFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSGrafanaAccountAdministrator",
    description: "Provides access within Amazon Grafana to create and manage workspaces for the entire organization.",
    name: "AWSGrafanaAccountAdministrator",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSBackupOperatorAccess",
    description:
      "This policy grants users permissions to assign AWS resources to backup plans, create on-demand backups, and restore backups. This policy does not allow the user to create or edit backup plans or to delete scheduled backups after they are created.",
    name: "AWSBackupOperatorAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSBudgetsActionsRolePolicyForResourceAdministrationWithSSM",
    description:
      "This policy gives AWS Budgets broad permission to control AWS resources. For example, to start and stop EC2 or RDS instances by executing AWS Systems Manager (SSM) scripts.",
    name: "AWSBudgetsActionsRolePolicyForResourceAdministrationWithSSM",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSBackupServiceRolePolicyForS3Restore",
    description:
      "Policy containing permissions necessary for AWS Backup to restore a S3 backup to a bucket. This includes read/write permissions to all S3 buckets, and permissions to GenerateDataKey and DescribeKey for all KMS keys.",
    name: "AWSBackupServiceRolePolicyForS3Restore",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonLookoutEquipmentFullAccess",
    description: "Provides full access to Amazon Lookout for Equipment operations",
    name: "AmazonLookoutEquipmentFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSPurchaseOrdersServiceRolePolicy",
    description: "Grants permissions to view and modify purchase orders on billing console",
    name: "AWSPurchaseOrdersServiceRolePolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonHoneycodeTeamAssociationReadOnlyAccess",
    description: "Provides read only access to Honeycode Team Association via the AWS Management Console and the SDK.",
    name: "AmazonHoneycodeTeamAssociationReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonWorkSpacesServiceAccess",
    description: "Provides customer account access to AWS WorkSpaces service for launching a Workspace.",
    name: "AmazonWorkSpacesServiceAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSSecurityHubOrganizationsAccess",
    description:
      "Grants permission to enable and manage AWS Security Hub within an organization. Includes enabling the service across the organization, and determining the delegated administrator account for the service.",
    name: "AWSSecurityHubOrganizationsAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElasticFileSystemsUtils",
    description:
      "Allows customers to use AWS Systems Manager to automatically manage Amazon EFS utilities (amazon-efs-utils) package on their EC2 instances, and use CloudWatchLog to get EFS file system mount success/failure notifications.",
    name: "AmazonElasticFileSystemsUtils",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSTransferConsoleFullAccess",
    description: "Provides full access to AWS Transfer via the AWS Management Console",
    name: "AWSTransferConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTWirelessLogging",
    description: "Allows the associated identity to create Amazon CloudWatch Logs groups and stream logs to the groups.",
    name: "AWSIoTWirelessLogging",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTWirelessFullAccess",
    description: "Allows the associated identity full access to all AWS IoT Wireless operations.",
    name: "AWSIoTWirelessFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElementalMediaLiveReadOnly",
    description: "Provides read only access to AWS Elemental MediaLive resources",
    name: "AWSElementalMediaLiveReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElasticFileSystemClientReadOnlyAccess",
    description: "Provides read only client access to an Amazon EFS file system",
    name: "AmazonElasticFileSystemClientReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElasticMapReducePlacementGroupPolicy",
    description: "Policy to allow EMR to create, describe and delete EC2 placement groups.",
    name: "AmazonElasticMapReducePlacementGroupPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElasticDisasterRecoveryConsoleFullAccess",
    description:
      "This policy provides full access to all public APIs of AWS Elastic Disaster Recovery (DRS), as well as permissions to read KMS key, License Manager, Resource Groups, Elastic Load Balancing, IAM, and EC2 information. Attach this policy to your IAM users or roles.",
    name: "AWSElasticDisasterRecoveryConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMemoryDBFullAccess",
    description: "Provides full access to Amazon MemoryDB via the AWS Management Console.",
    name: "AmazonMemoryDBFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonKeyspacesReadOnlyAccess",
    description: "Provide read only access to Amazon Keyspaces",
    name: "AmazonKeyspacesReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElasticDisasterRecoveryAgentInstallationPolicy",
    description:
      "This policy allows installing the AWS Replication Agent, which is used with AWS Elastic Disaster Recovery (DRS) to recover external servers to AWS. Attach this policy to your IAM users or roles whose credentials you provide during the installation step of the AWS Replication Agent.",
    name: "AWSElasticDisasterRecoveryAgentInstallationPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMigrationHubStrategyCollector",
    description:
      "Grants permissions to allow communication with the AWS Migration Hub Strategy Recommendations service, read/write access to S3 buckets related to the service, Amazon API Gateway access to upload logs and metrics to AWS, AWS Secrets Manager access to fetch credentials, and any related services.",
    name: "AWSMigrationHubStrategyCollector",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSResourceAccessManagerResourceShareParticipantAccess",
    description: "Provides access to AWS Resource Access Manager APIs needed by a resource share participant.",
    name: "AWSResourceAccessManagerResourceShareParticipantAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRedshiftQueryEditorV2ReadWriteSharing",
    description:
      "Grants the ability to work with Amazon Redshift Query Editor V2 with sharing of resources. The granted principal can read, write and share its own resources. The granted principal can read and update the resources shared with its team. This policy also grants access to other required services. This includes permissions to list the Amazon Redshift clusters and manage the Query Editor V2 secrets of the principal in AWS Secrets Manager.",
    name: "AmazonRedshiftQueryEditorV2ReadWriteSharing",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSBillingReadOnlyAccess",
    description: "Allows users to view bills on the Billing Console.",
    name: "AWSBillingReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSBackupFullAccess",
    description:
      "This policy is for backup administrators, granting full access to AWS Backup operations, including creating or editing backup plans, assigning AWS resources to backup plans, deleting backups, and restoring backups.",
    name: "AWSBackupFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonCodeGuruProfilerReadOnlyAccess",
    description: "Provides read only access to Amazon CodeGuru Profiler.",
    name: "AmazonCodeGuruProfilerReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/ElementalActivationsGenerateLicenses",
    description: "Access to view purchased assets and generate software licenses for pending activations",
    name: "ElementalActivationsGenerateLicenses",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSNetworkManagerReadOnlyAccess",
    description: "Provides read only access to Amazon NetworkManager via the AWS Management Console.",
    name: "AWSNetworkManagerReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonDevOpsGuruOrganizationsAccess",
    description: "Provide access to enable and manage Amazon DevOps Guru within an organization.",
    name: "AmazonDevOpsGuruOrganizationsAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSApplicationMigrationReadOnlyAccess",
    description:
      "This policy provides permissions to all read-only public APIs of Application Migration Service (MGN), as well as some read-only APIs of other AWS services that are required in order to make full read-only use of the MGN console. Attach this policy to your IAM users or roles.",
    name: "AWSApplicationMigrationReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSServiceCatalogAppRegistryReadOnlyAccess",
    description: "Provides read-only access to Service Catalog App Registry capabilites",
    name: "AWSServiceCatalogAppRegistryReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRoute53RecoveryReadinessReadOnlyAccess",
    description: "Provides read only access to Amazon Route 53 Recovery Readiness",
    name: "AmazonRoute53RecoveryReadinessReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonWorkMailMessageFlowReadOnlyAccess",
    description: "Read only access to WorkMail messages for the GetRawMessageContent API",
    name: "AmazonWorkMailMessageFlowReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudWatchSyntheticsFullAccess",
    description: "Provides full access to CloudWatch Synthetics.",
    name: "CloudWatchSyntheticsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDataExchangeSubscriberFullAccess",
    description:
      "Grants data subscriber access to AWS Data Exchange and AWS Marketplace actions using the AWS Management Console and SDK. It also provides select access to related services needed to take full advantage of AWS Data Exchange.",
    name: "AWSDataExchangeSubscriberFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/IAMAccessAnalyzerFullAccess",
    description: "Provides full access to IAM Access Analyzer",
    name: "IAMAccessAnalyzerFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodeArtifactAdminAccess",
    description: "Provides full access to AWS CodeArtifact via the AWS Management Console.",
    name: "AWSCodeArtifactAdminAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSServiceCatalogAdminReadOnlyAccess",
    description: "Provides read-only access to Service Catalog admin capabilities ",
    name: "AWSServiceCatalogAdminReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMonitronFullAccess",
    description: "Provides full access to manage Amazon Monitron",
    name: "AmazonMonitronFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonHealthLakeReadOnlyAccess",
    description: "Provides read only access to Amazon HealthLake service.",
    name: "AmazonHealthLakeReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonWorkSpacesSelfServiceAccess",
    description: "Provides access to Amazon WorkSpaces backend service to perform Workspace Self Service actions",
    name: "AmazonWorkSpacesSelfServiceAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRoute53RecoveryClusterFullAccess",
    description: "Provides full access to Amazon Route 53 Recovery Cluster",
    name: "AmazonRoute53RecoveryClusterFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonCloudWatchRUMReadOnlyAccess",
    description: "Grants read only permissions for the Amazon CloudWatch RUM service",
    name: "AmazonCloudWatchRUMReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSThinkboxDeadlineSpotEventPluginAdminPolicy",
    description:
      "Grants permissions required for AWS Thinkbox's Deadline Spot Event Plugin.  This includes permission to request, modify, and cancel a spot fleet, as well as limited PassRole permission.",
    name: "AWSThinkboxDeadlineSpotEventPluginAdminPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonLookoutMetricsReadOnlyAccess",
    description: "Gives access to all read-only actions for Amazon Lookout for Metrics",
    name: "AmazonLookoutMetricsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDataExchangeFullAccess",
    description:
      "Grants full access to AWS Data Exchange and AWS Marketplace actions using the AWS Management Console and SDK. It also provides select access to related services needed to take full advantage of AWS Data Exchange.",
    name: "AWSDataExchangeFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSDataExchangeProviderFullAccess",
    description:
      "Grants data provider access to AWS Data Exchange and AWS Marketplace actions using the AWS Management Console and SDK. It also provides select access to related services needed to take full advantage of AWS Data Exchange. ",
    name: "AWSDataExchangeProviderFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSBugBustFullAccess",
    description: "This IAM policy grants users full access to the AWS BugBust console",
    name: "AWSBugBustFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/CloudWatchApplicationInsightsFullAccess",
    description: "Provides full access to CloudWatch Application Insights and required dependencies. ",
    name: "CloudWatchApplicationInsightsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRoute53ResolverFullAccess",
    description: "Full access policy for Route 53 Resolver",
    name: "AmazonRoute53ResolverFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSServiceCatalogAppRegistryFullAccess",
    description: "Provides full access to Service Catalog App Registry capabilities",
    name: "AWSServiceCatalogAppRegistryFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonSageMakerAdmin-ServiceCatalogProductsServiceRolePolicy",
    description:
      "Service role policy used by the AWS Service Catalog service to provision products from Amazon SageMaker portfolio of products. Grants permissions to a set of related services including CodePipeline, CodeBuild, CodeCommit, Glue, CloudFormation, etc,.",
    name: "AmazonSageMakerAdmin-ServiceCatalogProductsServiceRolePolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonMSKConnectReadOnlyAccess",
    description: "Provide readonly access to Amazon MSK Connect",
    name: "AmazonMSKConnectReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSOpsWorksRegisterCLI_EC2",
    description: "Policy to enable registration of EC2 instances via the OpsWorks CLI",
    name: "AWSOpsWorksRegisterCLI_EC2",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSWAFConsoleReadOnlyAccess",
    description:
      "Provides read-only access to AWS WAF via the AWS Management Console. Note that this policy also grants permissions to list Amazon CloudFront distributions, permissions to view load balancers on AWS Elastic Load Balancing, permissions to view Amazon API Gateway REST APIs and stages, permissions to list and view Amazon CloudWatch metrics, and permissions to view regions enabled within the account.",
    name: "AWSWAFConsoleReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSSavingsPlansFullAccess",
    description: "Provides full access to Savings Plans service",
    name: "AWSSavingsPlansFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonCodeGuruProfilerAgentAccess",
    description: "Provides access required by Amazon CodeGuru Profiler agent.",
    name: "AmazonCodeGuruProfilerAgentAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonLookoutVisionConsoleFullAccess",
    description: "Provides full access to Amazon Lookout for Vision and scoped access to required service and console dependencies.",
    name: "AmazonLookoutVisionConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSAccountManagementFullAccess",
    description: "Provides full access to AWS Account Management.",
    name: "AWSAccountManagementFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSAppMeshPreviewEnvoyAccess",
    description: "App Mesh Preview Envoy policy for accessing Virtual Node configuration.",
    name: "AWSAppMeshPreviewEnvoyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSElementalMediaTailorFullAccess",
    description: "Provides full access to AWS Elemental MediaTailor resources",
    name: "AWSElementalMediaTailorFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMigrationHubStrategyConsoleFullAccess",
    description:
      "Grants full access to the AWS Migration Hub Strategy Recommendations service and access to related AWS services through the AWS Management Console.",
    name: "AWSMigrationHubStrategyConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIotRoboRunnerReadOnly",
    description: "This policy grants permissions that allow read-only access to AWS Iot RoboRunner.",
    name: "AWSIotRoboRunnerReadOnly",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTWirelessGatewayCertManager",
    description: "Allows the associated identity access to create, list and describe IoT Certificates",
    name: "AWSIoTWirelessGatewayCertManager",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSApplicationMigrationEC2Access",
    description:
      "This policy provides Amazon EC2 operations required to use Application Migration Service (MGN) to launch the migrated servers as EC2 instances. Attach this policy to your IAM users or roles.",
    name: "AWSApplicationMigrationEC2Access",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonCloudWatchEvidentlyFullAccess",
    description:
      "Provides full only access to Amazon CloudWatch Evidently. Also provides access to related Amazon S3, Amazon SNS, Amazon CloudWatch, and other related services.",
    name: "AmazonCloudWatchEvidentlyFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSImageBuilderReadOnlyAccess",
    description: "Provides read only access to all AWS Image Builder actions.",
    name: "AWSImageBuilderReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSGrafanaConsoleReadOnlyAccess",
    description: "Access to read only operations in Amazon Grafana.",
    name: "AWSGrafanaConsoleReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMarketplaceMeteringRegisterUsage",
    description: "Provides permissions to register a resource and track usage through AWS Marketplace Metering Service.",
    name: "AWSMarketplaceMeteringRegisterUsage",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonManagedBlockchainReadOnlyAccess",
    description: "Provides read-only access to Amazon Managed Blockchain.",
    name: "AmazonManagedBlockchainReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonLookoutVisionReadOnlyAccess",
    description: "Provides read only access to Amazon Lookout for Vision and scoped access to required dependencies.",
    name: "AmazonLookoutVisionReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRekognitionCustomLabelsFullAccess",
    description: "This policy specifies rekognition and s3 permissions required by Amazon Rekognition Custom Labels feature.",
    name: "AmazonRekognitionCustomLabelsFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonHealthLakeFullAccess",
    description: "Provides full access to Amazon HealthLake service.",
    name: "AmazonHealthLakeFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonManagedBlockchainConsoleFullAccess",
    description: "Provides full access to Amazon Managed Blockchain via the AWS Management Console",
    name: "AmazonManagedBlockchainConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSSavingsPlansReadOnlyAccess",
    description: "Provides read only access to Savings Plans service",
    name: "AWSSavingsPlansReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSMigrationHubRefactorSpacesFullAccess",
    description:
      "Grants full access to AWS MigrationHub Refactor Spaces, AWS MigrationHub Refactor Spaces console features and other related AWS services except permissions required for AWS Lambda and AWS Resource Access Manager as they can be scoped down based on tags.",
    name: "AWSMigrationHubRefactorSpacesFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonHoneycodeWorkbookFullAccess",
    description: "Provides full access to Honeycode Workbook via the AWS Management Console and the SDK.",
    name: "AmazonHoneycodeWorkbookFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSIoTDeviceTesterForGreengrassFullAccess",
    description:
      "Allows AWS IoT Device Tester to run the AWS Greengrass qualification suite by allowing access to related services including Lambda, IoT, API Gateway, IAM",
    name: "AWSIoTDeviceTesterForGreengrassFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonWorkMailMessageFlowFullAccess",
    description: "Full access to the WorkMail Message Flow APIs",
    name: "AmazonWorkMailMessageFlowFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSThinkboxDeadlineResourceTrackerAccessPolicy",
    description:
      "Grants permissions required for the operation of AWS Thinkbox's Deadline Resource Tracker.  This includes full access to some EC2 actions, including DeleteFleets and CancelSpotFleetRequests.",
    name: "AWSThinkboxDeadlineResourceTrackerAccessPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSLakeFormationDataAdmin",
    description: "Grants administrative access to AWS Lake Formation and related services, such as AWS Glue, to manage data lakes",
    name: "AWSLakeFormationDataAdmin",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSLambda_FullAccess",
    description: "Grants full access to AWS Lambda service, AWS Lambda console features, and other related AWS services.",
    name: "AWSLambda_FullAccess",
  },
  { arn: "arn:aws:iam::aws:policy/AWSDenyAll", description: "Deny all access.", name: "AWSDenyAll" },
  { arn: "arn:aws:iam::aws:policy/AWSIQFullAccess", description: "Provides full access to AWS IQ", name: "AWSIQFullAccess" },
  {
    arn: "arn:aws:iam::aws:policy/AmazonElasticContainerRegistryPublicPowerUser",
    description: "Provides full access to Amazon ECR Public repositories, but does not allow repository deletion or policy changes.",
    name: "AmazonElasticContainerRegistryPublicPowerUser",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonPrometheusConsoleFullAccess",
    description: "Grants full access to AWS Managed Prometheus resources in the AWS console",
    name: "AmazonPrometheusConsoleFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonEKSVPCResourceController",
    description: "Policy used by VPC Resource Controller to manage ENI and IPs for worker nodes.",
    name: "AmazonEKSVPCResourceController",
  },
  {
    arn: "arn:aws:iam::aws:policy/EC2InstanceConnect",
    description:
      "Allows customers to call EC2 Instance Connect to publish ephemeral keys to their EC2 instances and connect via ssh or the EC2 Instance Connect CLI.",
    name: "EC2InstanceConnect",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCompromisedKeyQuarantineV2",
    description:
      "Denies access to certain actions, applied by the AWS team in the event that an IAM user's credentials have been compromised or exposed publicly. Do NOT remove this policy. Instead, please follow the instructions specified in the support case created for you regarding this event.",
    name: "AWSCompromisedKeyQuarantineV2",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonAppFlowFullAccess",
    description:
      "Provides full access to Amazon AppFlow and access to AWS services supported as flow source or destination (S3 and Redshift). Also provides access to KMS for encryption",
    name: "AmazonAppFlowFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/Ec2ImageBuilderCrossAccountDistributionAccess",
    description: "Permissions need by EC2 Image Builder to perform a cross account distribution.",
    name: "Ec2ImageBuilderCrossAccountDistributionAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSThinkboxAWSPortalWorkerPolicy",
    description: "This policy grants the Deadline Workers in AWS Portal the necessary permissions required for normal operation.",
    name: "AWSThinkboxAWSPortalWorkerPolicy",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonS3OutpostsReadOnlyAccess",
    description: "Provides read only access to Amazon S3 on Outposts via the AWS Management Console.",
    name: "AmazonS3OutpostsReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCompromisedKeyQuarantine",
    description:
      "Denies access to certain actions, applied by the AWS team in the event that an IAM user's credentials have been compromised or exposed publicly.  Do NOT remove this policy.  Instead, please follow the instructions specified in the email sent to you regarding this event.",
    name: "AWSCompromisedKeyQuarantine",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSAppMeshEnvoyAccess",
    description: "App Mesh Envoy policy for accessing Virtual Node configuration.",
    name: "AWSAppMeshEnvoyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRoute53RecoveryClusterReadOnlyAccess",
    description: "Provides read only access to Amazon Route 53 Recovery Cluster",
    name: "AmazonRoute53RecoveryClusterReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonKendraReadOnlyAccess",
    description: "Provides read only access to Amazon Kendra via the AWS Management Console.",
    name: "AmazonKendraReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonPrometheusFullAccess",
    description: "Grants full access to AWS Managed Prometheus resources",
    name: "AmazonPrometheusFullAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonOpenSearchServiceReadOnlyAccess",
    description: "Provides read-only access to the Amazon OpenSearch Service configuration service.",
    name: "AmazonOpenSearchServiceReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonNimbleStudio-StudioAdmin",
    description:
      "This policy grants access to Amazon Nimble Studio resources associated with the studio admin and related studio resources in other services. Attach this policy to the Admin role associated with your studio.",
    name: "AmazonNimbleStudio-StudioAdmin",
  },
  {
    arn: "arn:aws:iam::aws:policy/AWSCodeArtifactReadOnlyAccess",
    description: "Provides read only access to AWS CodeArtifact via the AWS Management Console.",
    name: "AWSCodeArtifactReadOnlyAccess",
  },
  {
    arn: "arn:aws:iam::aws:policy/AmazonRedshiftDataFullAccess",
    description:
      "This policy provides full access to Amazon Redshift Data APIs. This policy also grants scoped access to other required services.",
    name: "AmazonRedshiftDataFullAccess",
  },
];

export default awsManagedPolicies;
