<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid" /> {{ caption }}
        <input type="hidden" id="list-users" :value="listUsers" />
        <CButton
          v-if="listUsers.length > 0"
          @click.stop.prevent="copyListUsers"
          size="sm"
          color="secondary"
          class=""
          >Copy Users</CButton
        >
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="itemsExt"
        :fields="fields"
        :items-per-page="small ? 20 : 10"
        :dark="dark"
        :loading="loading"
        pagination
        sorter
        tableFilter
        :sorterValue="{ column: 'RoleName', asc: true }"
        items-per-page-select
      >
        <template #action="{ item, index }">
          <td class="py-2">
            <CButton
              v-if="$isAccountOwner(accountId)"
              color="danger"
              variant="outline"
              square
              size="sm"
              @click="unassignUser(item, index)"
              :disabled="item.action !== 'Delete'"
              >{{ item.action }}</CButton
            >
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "TableUserList",
  props: {
    loading: Boolean,
    accountId: String,
    items: Array,
    fields: {
      type: Array,
      default() {
        return ["GID", "name", "action"];
      }
    },
    caption: {
      type: String,
      default: "Table"
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    unassignFct: Function
  },
  computed: {
    itemsExt: function() {
      if (this.items && this.items != undefined) {
        var res = this.items.map((item, id) => {
          return { ...item, id, action: "Delete" };
        });
        return res;
      } else {
        return [];
      }
    },
    listUsers: function() {
      let temp = "";
      for (let i in this.items) {
        temp = temp + this.items[i].GID.split("@")[0] + ",";
      }
      console.log(temp.slice(0, -1));
      return temp.slice(0, -1);
    }
  },
  data() {
    return {};
  },
  methods: {
    copyListUsers() {
      let listUsers = document.querySelector("#list-users");
      listUsers.setAttribute("type", "text");
      listUsers.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        alert("Users copied " + msg);
      } catch (err) {
        alert("Oops, unable to copy");
      }

      /* unselect the range */
      listUsers.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    async unassignUser(item) {
      var res = true;
      item.action = "Pending";
      if (this.unassignFct) {
        res = await this.unassignFct(item);
        if (!res.success) {
          item.action = "Delete";
        }
      }
    }
  }
};
</script>
