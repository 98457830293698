<template>
  <div>
    <div v-if="!hide">
      <CCard>
        <CCardHeader>
          <slot name="header">
            <CIcon name="cil-grid" /> {{ caption }}
            <div class="card-header-actions">
              <a
                href="#close"
                class="card-header-action"
                rel="noreferrer noopener"
                @click="display(false)"
              >
                <small class="text-muted">close</small>
              </a>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :hover="hover"
            :striped="true"
            :border="border"
            :small="small"
            :fixed="fixed"
            :items="items"
            :fields="fields"
            :items-per-page="small ? 20 : 10"
            :dark="dark"
            pagination
            tableFilter
          >
            <template #action="{ item }">
              <td class="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  @click="add(item)"
                  >+</CButton
                >
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <CButton
        color="secondary"
        @click="display(true)"
        v-c-tooltip="{
          content: 'Click to search and add managed policies',
          placement: 'right',
        }"
        >Search policies</CButton
      >
    </div>
  </div>
</template>

<script>
import AwsManagedPolicies from "./mockAWSManagedPolicies";

export default {
  name: "TableAWSManagedPolicies",
  props: {
    fields: {
      type: Array,
      default() {
        return ["name", "description", "action"];
      }
    },
    caption: {
      type: String,
      default: "AWS Managed Policies Search"
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      items: AwsManagedPolicies,
      hide: true
    };
  },
  methods: {
    clicked(event) {
      console.log("click" + JSON.stringify(event));
      this.$emit("row-clicked", event);
    },
    async add(item) {
      this.$emit("add", item);
      this.display(false);
    },
    async display(show) {
      this.hide = !show;
    }
  }
};
</script>
